import React from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'

import Icon from 'components/Icon'

const DropdownButton = ({
  customButton,
  disabled,
  name,
  icon,
  toggleGui,
  btnClassName,
  placeholder,
}) => (
  <div
    className='dropdown-trigger'
    onClick={() => toggleGui('showPopup')}
  >
    {customButton || (
      <button disabled={disabled} className={cn('button', btnClassName)}>
        {icon && <Icon icon={icon} />}
        {name ? (
          <span>{name}</span>
        ) : (
          placeholder && (
            <span className='dropdown-placeholder'>{placeholder}</span>
          )
        )}
        <Icon icon='angle-down' />
      </button>
    )}
  </div>
)
DropdownButton.propTypes = {
  name: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.node,
  ]),
  toggleGui: PropTypes.func.isRequired,
  icon: PropTypes.string,
  placeholder: PropTypes.string,
  btnClassName: PropTypes.string,
}

export default DropdownButton
