import React from 'react'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'

import store from 'redux/store'
import history from 'utils/history'
import App from 'components/root/App'

export default () => (
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>
)
