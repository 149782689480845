export const SET_SGL_USER_PROFILE = 'SET_SGL_USER_PROFILE'
export const CLEAR_SGL_USER_INFO = 'CLEAR_SGL_USER_INFO'
export const EDIT_SGL_USER_PROP = 'EDIT_SGL_USER_PROP'
export const ERROR_SGL_USER = 'ERROR_SGL_USER'
export const SET_SGL_TRANSACTIONS = 'SET_SGL_TRANSACTIONS'
export const SET_SGL_ADS = 'SET_SGL_ADS'
export const SET_SGL_COMPENSATIONS = 'SET_SGL_COMPENSATIONS'
export const ADD_SGL_COMPENSATIONS = 'ADD_SGL_COMPENSATIONS'
export const SET_SGL_REPORTS = 'SET_SGL_REPORTS'
export const ADD_SGL_SKILL = 'ADD_SGL_SKILL'
export const REMOVE_SGL_SKILL = 'REMOVE_SGL_SKILL'
export const REMOVE_SGL_ITEM = 'REMOVE_SGL_ITEM'
export const ADD_SKILL_CATEGORY = 'ADD_SKILL_CATEGORY'
export const SGL_BAN_INFO = 'SGL_BAN_INFO'
export const SET_SGL_ALL_FLAGS = 'SET_SGL_ALL_FLAGS'
export const SET_SGL_USER_FLAGS = 'SET_SGL_USER_FLAGS'
export const SET_SGL_BACKUPS = 'SET_SGL_BACKUPS'
export const SET_SGL_IDENTITIES = 'SET_SGL_IDENTITIES'
export const SET_SGL_USER_GUILD = 'SET_SGL_USER_GUILD'
export const SGL_USER_RANKED_UP = 'SGL_USER_RANKED_UP'

export const clearSglUserInfo = () => ({
  type: CLEAR_SGL_USER_INFO,
})

export const errorSglUser = (section, err) => ({
  type: ERROR_SGL_USER,
  section,
  err,
})

export const editSglUserProp = (path, value, propName) => ({
  type: EDIT_SGL_USER_PROP,
  path,
  value,
  propName,
})

export const addSglSkill = (skill) => ({
  type: ADD_SGL_SKILL,
  skill,
})

export const removeSglSkill = (index) => ({
  type: REMOVE_SGL_SKILL,
  index,
})

export const addSkillCategory = (categoryId, skillId) => {
  const category = {
    CategoryID: categoryId,
    ID: skillId,
    IsLocked: false,
    Level: 1,
  }
  return {
    type: ADD_SKILL_CATEGORY,
    category,
  }
}

export const sglUserRankedUp = () => ({
  type: SGL_USER_RANKED_UP,
})

export const removeSglItem = (itemId) => ({
  type: REMOVE_SGL_ITEM,
  itemId,
})
