import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Icon from 'components/Icon'
import Link from 'components/Link'

import data from './tilesData'

import withAuth from 'utils/auth/withAuth'

const Tiles = ({ loggedUser: { permissions } }) => {
  const tiles = filterTiles(permissions)
  return (
    <div>
      {_.chunk(tiles, 4).map((chunk, i) => (
        <div className='tile is-ancestor' key={i}>
          {chunk.map((tile, j) => (
            <div className='tile is-3 is-parent' key={j}>
              <Link className='tile is-child box' to={tile.href}>
                <div className='title'>
                  <Icon icon={tile.icon} className='has-margin-right' />
                  <span>{tile.title}</span>
                </div>
                <div>{tile.description}</div>
              </Link>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

const filterTiles = (permissions) =>
  data.filter(
    (tile) => !tile.permission || permissions.includes(tile.permission),
  )

Tiles.propTypes = {
  loggedUser: PropTypes.object.isRequired,
}

export default withAuth()(Tiles)
