import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import GetShareUrl from 'components/GetShareUrl'
import Dropdown from 'components/Dropdown'
import Button from 'components/Button'

import RequestProgress from './RequestProgress'

import cloud from 'core/cloud'
import app from 'core/app'
import user from 'core/user'

import { renameProduct } from 'utils/misc'

class CloudSettings extends Component {
  render() {
    const {
      cloud: { appVer, prodId, cloudVer, appVers },
      loggedUser,
      options,
      requests,
    } = this.props

    return (
      <>
        <GetShareUrl params={['cloudVer', 'appVer', 'prodId']}>
          <Button
            className='is-tooltip-bottom is-toggle'
            icon='link'
            tooltip='Get shareable link with current Cloud settings.'
          />
        </GetShareUrl>

        <RequestProgress requests={requests} />

        {options.cloudVer && (
          <Dropdown
            className='is-right'
            btnClassName='is-toggle'
            name={cloudVer}
            options={_.map(loggedUser.cloud, (cloudVersion, i) => ({
              name: cloudVersion,
              onClick: () => this.props.setCloudVer(cloudVersion),
            }))}
          />
        )}

        {(options.appVer || options.prodId) && (
          <div className='flex'>
            {options.prodId && (
              <Dropdown
                className='is-right'
                btnClassName='is-toggle'
                name={renameProduct(prodId)}
                options={_.map(_.keys(appVers), (product, i) => ({
                  name: renameProduct(product),
                  onClick: () => this.props.setProdId(product),
                }))}
              />
            )}

            {options.appVer && (
              <Dropdown
                className='is-right'
                btnClassName='is-toggle'
                name={appVer}
                options={_.map(appVers[prodId][cloudVer], (appVersion, i) => ({
                  name: appVersion,
                  onClick: () => this.props.setAppVer(appVersion),
                }))}
              />
            )}
          </div>
        )}
      </>
    )
  }
}

CloudSettings.propTypes = {
  cloud: PropTypes.object.isRequired,
  loggedUser: PropTypes.object.isRequired,
  requests: PropTypes.array.isRequired,
  options: PropTypes.object.isRequired,

  setCloudVer: PropTypes.func.isRequired,
  setProdId: PropTypes.func.isRequired,
  setAppVer: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  requests: app.getRequests(state),
  cloud: cloud.getCloud(state),
  loggedUser: user.getInfo(state),
})

const mapActionsToProps = (dispatch) => bindActionCreators(cloud, dispatch)

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(CloudSettings)
