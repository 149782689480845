import { NAME } from './constants'

export const SET_CMS_PRODUCT = `${NAME}/SET_CMS_PRODUCT`
export const SET_CMS_META = `${NAME}/SET_CMS_META`
export const SET_CMS_SECTION = `${NAME}/SET_CMS_SECTION`
export const EDIT_CMS_BASIC_ELEMENT = `${NAME}/EDIT_CMS_BASIC_ELEMENT`
export const ADD_CMS_ITEM = `${NAME}/ADD_CMS_ITEM`
export const ADD_CMS_DOC_TYPE = `${NAME}/ADD_CMS_DOC_TYPE`
export const CREATE_CMS_DOC_TYPE = `${NAME}/CREATE_CMS_DOC_TYPE`
export const DELETE_CMS_DOC_TYPE = `${NAME}/DELETE_CMS_DOC_TYPE`
export const DELETE_CMS_ITEM = `${NAME}/DELETE_CMS_ITEM`
export const SWITCH_CMS_ITEMS = `${NAME}/SWITCH_CMS_ITEMS`
export const MOVE_CMS_ITEM_TOP = `${NAME}/MOVE_CMS_ITEM_TOP`
export const ADD_CMS_SECTION = `${NAME}/ADD_CMS_SECTION`
export const DELETE_CMS_SECTION = `${NAME}/DELETE_CMS_SECTION`
export const SET_CMS_DOC_TYPE_LIMIT = `${NAME}/SET_CMS_DOC_TYPE_LIMIT`
export const SET_CMS_DOC_TYPE_FIELDS = `${NAME}/SET_CMS_DOC_TYPE_FIELDS`
export const SET_CMS_SECTION_ACCESS = `${NAME}/SET_CMS_SECTION_ACCESS`
export const CHANGE_CMS_LOCALE = `${NAME}/CHANGE_CMS_LOCALE`
export const SELECT_CMS_ITEM = `${NAME}/SELECT_CMS_ITEMS`
export const TOGGLE_CMS_GUI = `${NAME}/TOGGLE_CMS_GUI`
export const CLEAR_CMS = `${NAME}/CLEAR_CMS`
export const SELECT_CMS_DOC_TYPE = `${NAME}/SELECT_CMS_DOC_TYPE`
export const CMS_PUBLISH_SUCCESS = `${NAME}/CMS_PUBLISH_SUCCESS`
export const DELETE_CMS_SECTION_DOC_TYPE = `${NAME}/DELETE_CMS_SECTION_DOC_TYPE`

export const EDIT_CMS_ITEM_TRANSLATION = `${NAME}/EDIT_CMS_ITEM_TRANSLATION`
export const ADD_CMS_ITEM_TRANSLATION = `${NAME}/ADD_CMS_ITEM_TRANSLATION`

export const clearCms = () => ({
  type: CLEAR_CMS,
})

export const editCmsBasicElement = (
  section,
  docType,
  itemId,
  propName,
  value,
) => ({
  type: EDIT_CMS_BASIC_ELEMENT,
  section,
  docType,
  itemId,
  propName,
  value,
})

export const addCmsItem = (section, docType, data) => ({
  type: ADD_CMS_ITEM,
  section,
  docType,
  data,
})

export const deleteCmsItem = (section, docType, itemId) => ({
  type: DELETE_CMS_ITEM,
  section,
  docType,
  itemId,
})

export const switchCmsItems = ({ section, docType, oldIndex, newIndex }) => ({
  type: SWITCH_CMS_ITEMS,
  section,
  docType,
  oldIndex,
  newIndex,
})

export const addCmsSection = (sectionName) => ({
  type: ADD_CMS_SECTION,
  sectionName,
})

export const deleteCmsSection = (sectionName) => ({
  type: DELETE_CMS_SECTION,
  sectionName,
})

export const selectCmsDocType = (docType) => ({
  type: SELECT_CMS_DOC_TYPE,
  docType,
})

export const createCmsDocType = (name, fields) => ({
  type: CREATE_CMS_DOC_TYPE,
  name,
  fields,
})

export const setDocTypeLimit = (docType, limit) => ({
  type: SET_CMS_DOC_TYPE_LIMIT,
  docType,
  limit,
})

export const setDocTypeFields = (docType, fields) => ({
  type: SET_CMS_DOC_TYPE_FIELDS,
  docType,
  fields,
})

export const deleteDocType = (docTypeName) => ({
  type: DELETE_CMS_DOC_TYPE,
  docTypeName,
})

export const deleteSectionDocType = (sectionName, docTypeName) => ({
  type: DELETE_CMS_SECTION_DOC_TYPE,
  sectionName,
  docTypeName,
})

export const changeCmsLocale = (locale) => ({
  type: CHANGE_CMS_LOCALE,
  locale,
})

export const selectCmsItem = (path) => ({
  type: SELECT_CMS_ITEM,
  path,
})

export const toggleCmsGui = (prop) => ({
  type: TOGGLE_CMS_GUI,
  prop,
})

export const setSectionAccess = (sectionName, access) => ({
  type: SET_CMS_SECTION_ACCESS,
  sectionName,
  access,
})

export const addCmsDocType = (path) => ({
  type: ADD_CMS_DOC_TYPE,
  path,
})

export const moveCmsItemTop = (section, docType, itemId) => ({
  type: MOVE_CMS_ITEM_TOP,
  section,
  docType,
  itemId,
})

export const addCmsItemTranslation = (locale, itemId, data) => ({
  type: ADD_CMS_ITEM_TRANSLATION,
  locale,
  itemId,
  data,
})

export const editCmsItemTranslation = (locale, itemId, propName, value) => ({
  type: EDIT_CMS_ITEM_TRANSLATION,
  locale,
  itemId,
  propName,
  value,
})

export const cmsPublishSucces = (meta) => ({
  type: CMS_PUBLISH_SUCCESS,
  meta,
})

export const setCmsMeta = (docTypes) => ({
  type: SET_CMS_META,
  data: {
    docTypes,
    sections: {},
  },
})

export const setCmsSection = (sections, sectionName) => ({
  type: SET_CMS_SECTION,
  data: sections[sectionName],
  sectionName,
})