import React from 'react'

const NotFound = () => (
  <div className='main-view'>
    <h1>{'404'}</h1>
    <h3>{'Page not found'}</h3>
  </div>
)

export default NotFound
