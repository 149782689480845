// TODO: add keywords to make search utility useful :) !!!
export default {
  permissions:
    'auth authorization authentication right user management account',
  logs: 'logging',
  users: 'search legends shadowgun dead trigger gold money account',
  transactions:
    'purchases iap inapp in app ads advertisement videos tapjoy google play itunes apple money gold pack package premium account',
  reports: 'cheaters ban banning banned unban hackers chat complaints',
  cloud:
    'settings generic instances kill killer attributes ip whitelist hash library datastore entity new user cdn highwinds cache',
}
