import React from 'react'
import universal from 'react-universal-component'
import AsyncComponentLoading from 'components/AsyncComponentLoading'

const asyncComponent = () => import(/* webpackChunkName: "web" */ './Web')

const UniversalComponent = universal(asyncComponent, {
  resolve: () => require.resolveWeak('./Web'),
  chunkName: 'web',
  loading: AsyncComponentLoading,
})

export default (props) => <UniversalComponent {...props} />
