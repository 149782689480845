import React, { Component } from 'react'

import { getDisplayName } from 'utils/hoc'

import Error from 'components/Error'

const catchErrors = (WrappedComponent) =>
  class extends Component {
    constructor(props) {
      super(props)
      this.state = { error: null, info: null }
    }

    static displayName = `ErrorBoundary(${getDisplayName(WrappedComponent)})`

    componentDidCatch = (error, info) => {
      console.error({
        error,
        info,
      })
      this.setState({ error, info })
    }

    render() {
      const { error, info } = this.state

      console.log("error ", error, "info ", info)
      if (error) {
        return (
          <Error error={error} info={info} />
        )
      }
      return <WrappedComponent {...this.props} />
    }
  }

export default catchErrors
