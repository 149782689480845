import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'fonts' // add our fonts library

const Icon = (props) => {
  let { icon, size, onClick, className, tooltip, style, ...rest } = props
  if (size === '2x' || size === 'lg') {
    className += ' is-medium'
  }
  if (size === '3x') {
    className += ' is-large'
  }
  return (
    <span
      style={{
        cursor: onClick && 'pointer',
        ...style,
      }}
      onClick={onClick}
      className={cn('icon', className, { tooltip })}
      data-tooltip={tooltip}>
      <FontAwesomeIcon icon={icon} size={size} {...rest} />
    </span>
  )
}

Icon.defaultProps = {
  className: '',
}

Icon.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  size: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  style: PropTypes.object,
}

export default Icon
