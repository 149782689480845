import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Button from 'components/Button'

const RequestProgress = ({ requests }) => {
  const requestCount = requests.length

  return (
    <Button
      className={cn(
        requestCount > 0 && 'is-loading',
        'is-toggle is-tooltip-bottom',
      )}
      tooltip='Icon is spinning while fetching data from cloud.'
      icon='cloud'
    />
  )
}

RequestProgress.propTypes = {
  requests: PropTypes.array.isRequired,
}

export default RequestProgress
