var Settings = {
  DeadTrigger: {
    Ranks: [
      // I have to confess, I made these up...
      // ...similarities to the actuall values is purely coincidental
      0,
      1000,
      5000,
      11000,
      18000,
      26000,
      35000,
      45000,
      56000,
      68000,
      81000,
      95000,
      110000,
      126000,
      143000,
      161000,
      180000,
      200000,
      221000,
      243000,
      266000,
      290000,
      // now go crazy!!
      350000,
      500000,
      1000000,
      1500000,
      2000000,
      5000000,
      10000000,
      15000000,
    ],
  },
}

export const DeadTrigger = Settings.DeadTrigger

export default Settings
