import React, { Component } from 'react'
import hoistNonReactStatic from 'hoist-non-react-statics'

const withToggle = (Wrapped, initState = {}) => {
  class toggleGuiWrapper extends Component {
    constructor(props) {
      super(props)
      this.state = initState
    }

    static displayName = `WithToggleGui${Wrapped.displayName ||
      Wrapped.name ||
      'Component'}`

    toggleGui = (key, forceValue) => {
      this.setState({
        [key]: forceValue !== undefined ? forceValue : !this.state[key],
      })
    }

    render() {
      return (
        <Wrapped
          {...this.props}
          guiState={this.state}
          toggleGui={this.toggleGui}
        />
      )
    }
  }

  return hoistNonReactStatic(toggleGuiWrapper, Wrapped)
}

export default withToggle
