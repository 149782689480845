import NewhopeAdminCommon from '@mfg/cloud/newhope/admin'
import NewhopeAttributesCommon from '@mfg/cloud/newhope/attributes'
import NewhopeBanCommon from '@mfg/cloud/newhope/ban'
import NewhopeBanningCommon from '@mfg/cloud/newhope/banning'
import NewhopeEventsCommon from '@mfg/cloud/newhope/events'
import NewhopeIapCommon from '@mfg/cloud/newhope/iap'
import NewhopeItemsCommon from '@mfg/cloud/newhope/items'
import NewhopeLogsCommon from '@mfg/cloud/newhope/logs'
import NewhopeMessagesCommon from '@mfg/cloud/newhope/messages'
import NewhopeMiscCommon from '@mfg/cloud/newhope/misc'
import NewhopeRewardsCommon from '@mfg/cloud/newhope/rewards'
import NewhopeSearchCommon from '@mfg/cloud/newhope/search'
import NewhopeUnkilledCommon from '@mfg/cloud/newhope/unkilled'
import NewhopeVouchersCommon from '@mfg/cloud/newhope/vouchers'
import NewhopeUserCommon from '@mfg/cloud/newhope/user'

import { injectNewhopeConstructor } from './utils'

export const NewhopeAdmin = injectNewhopeConstructor(NewhopeAdminCommon)

export const NewhopeAttributes = injectNewhopeConstructor(
  NewhopeAttributesCommon,
)

export const NewhopeBan = injectNewhopeConstructor(NewhopeBanCommon)

export const NewhopeBanning = injectNewhopeConstructor(NewhopeBanningCommon)

export const NewhopeEvents = injectNewhopeConstructor(NewhopeEventsCommon)

export const NewhopeIap = injectNewhopeConstructor(NewhopeIapCommon)

export const NewhopeItems = injectNewhopeConstructor(NewhopeItemsCommon)

export const NewhopeLogs = injectNewhopeConstructor(NewhopeLogsCommon)

export const NewhopeMessages = injectNewhopeConstructor(NewhopeMessagesCommon)

export const NewhopeMisc = injectNewhopeConstructor(NewhopeMiscCommon)

export const NewhopeRewards = injectNewhopeConstructor(NewhopeRewardsCommon)

export const NewhopeSearch = injectNewhopeConstructor(NewhopeSearchCommon)

export const NewhopeUnkilled = injectNewhopeConstructor(NewhopeUnkilledCommon)

export const NewhopeVouchers = injectNewhopeConstructor(NewhopeVouchersCommon)

export const NewhopeUser = injectNewhopeConstructor(NewhopeUserCommon)
