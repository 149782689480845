import { ADD_LOGS, CLEAR_LOGS } from './actions'

const initLogs = []

const logs = (state = initLogs, action) => {
  switch (action.type) {
    case ADD_LOGS:
      return [...state, ...action.logs]

    case CLEAR_LOGS:
      return []
    default:
      return state
  }
}

export default logs
