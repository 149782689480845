import React from 'react'
import universal from 'react-universal-component'
import AsyncComponentLoading from 'components/AsyncComponentLoading'

const asyncComponent = () => import(/* webpackChunkName: "games" */ './Games')

const UniversalComponent = universal(asyncComponent, {
  resolve: () => require.resolveWeak('./Games'),
  chunkName: 'games',
  loading: AsyncComponentLoading,
})

export default (props) => <UniversalComponent {...props} />
