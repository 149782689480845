// load only used fonts to custom library
// see docs for more info https://github.com/FortAwesome/react-fontawesome

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons' // temp add all free fonts
import { far } from '@fortawesome/free-regular-svg-icons' // temp add all free fonts
import { fab } from '@fortawesome/free-brands-svg-icons' // temp add all free fonts
import custom from './customFonts'

library.add(fas, fab, far, custom)
