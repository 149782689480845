import * as types from '../types'

const init = {
  list: [],
  detail: {},
}

export const guilds = (state = init, action) => {
  switch (action.type) {
    case types.SET_GUILDS_LIST:
      return {
        ...state,
        list: action.guilds,
      }

    case types.SET_GUILD_DETAIL:
      return {
        ...state,
        detail: action.detail,
      }

    case types.EDIT_GUILD_DETAIL:
      return {
        ...state,
        detail: {
          ...state.detail,
          [action.key]: action.value,
        },
      }

    case types.REMOVE_GUILD_MEMBER:
      return {
        ...state,
        detail: {
          ...state.detail,
          Members: state.detail.Members.filter((member) => member.UID !== action.payload.uid),
          MemberCount: state.detail.MemberCount - 1,
        },
      }

    case types.PROMOTE_GUILD_MEMBER:
      return {
        ...state,
        detail: {
          ...state.detail,
          Leader: action.payload.memberNick,
          leaderUID: action.payload.memberUid,
        },
      }

    default:
      return state
  }
}
