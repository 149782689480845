import { combineReducers } from 'redux'

import other from './other'
import sections from './sections'
import meta from './meta'
import original from './original'
import translations from './translations'
import { hasChanges } from './changes'

const reducer = combineReducers({
  meta,
  original,
  sections,
  translations,
  hasChanges,
  other,
})

export default reducer
