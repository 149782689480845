import * as types from '../types'

const init = {
  locale: 'English',
  languages: [],
  origBadWordsList: [],
  badWordsList: [],
}

export function badWords(state = init, action) {
  switch (action.type) {
    case types.SET_LANGUAGE_LIST:
      return {
        ...state,
        languages: action.languages,
      }

    case types.SET_LOCALE:
      return {
        ...state,
        locale: action.locale,
      }

    case types.SET_BAD_WORDS:
      return {
        ...state,
        badWordsList: action.badWords,
      }

    case types.SET_ORIGINAL_BAD_WORDS:
      return {
        ...state,
        origBadWordsList: action.badWords,
      }

    case types.ADD_BAD_WORD:
      return {
        ...state,
        badWordsList: [...state.badWordsList, action.name],
      }

    case types.REMOVE_BAD_WORD:
      return {
        ...state,
        badWordsList: state.badWordsList.filter((w, i) => i !== action.index),
      }

    default:
      return state
  }
}
