import { NAME } from './constants'

export const START_EVENTS_FETCH = `${NAME}/START_EVENTS_FETCH`
export const START_EVENTS_REFRESH = `${NAME}/START_EVENTS_REFRESH`
export const CLEAR_GAME_EVENTS = `${NAME}/CLEAR_GAME_EVENTS`
export const FETCH_GAME_EVENTS = `${NAME}/FETCH_GAME_EVENTS`
export const CREATE_TOURNAMENT = `${NAME}/CREATE_TOURNAMENT`
export const ARCHIVE_TOURNAMENT = `${NAME}/ARCHIVE_TOURNAMENT`
export const EDIT_TOURNAMENT_FIELD = `${NAME}/EDIT_TOURNAMENT_FIELD`
export const DELETE_TOURNAMENT_FIELD = `${NAME}/DELETE_TOURNAMENT_FIELD`
export const RESTORE_TOURNAMENT = `${NAME}/RESTORE_TOURNAMENT`
export const WORLD_VERSION_SET = `${NAME}/WORLD_VERSION_SET`
export const CHANGE_EVENT_TYPE = `${NAME}/CHANGE_EVENT_TYPE`
export const SET_WEBGL_URL = `${NAME}/SET_WEBGL_URL`
export const REWARD_ITEM_REMOVE = `${NAME}/REWARD_ITEM_REMOVE`
export const REWARD_ITEM_ADD = `${NAME}/REWARD_ITEM_ADD`
export const REWARD_ITEM_EDIT = `${NAME}/REWARD_ITEM_EDIT`
export const REWARD_ITEM_SET = `${NAME}/REWARD_ITEM_SET`
export const REWARD_ADD = `${NAME}/REWARD_ADD`
export const REWARD_REMOVE = `${NAME}/REWARD_REMOVE`
export const REWARD_EDIT_LIMIT = `${NAME}/REWARD_EDIT_LIMIT`
