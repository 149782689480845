import React, { Component } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import Icon from 'components/Icon'

class Button extends Component {
  onClick = () => {
    if (!this.props.disabled && this.props.onClick) {
      this.props.onClick()
    }
  }

  onIconClick = (e) => {
    if (this.props.disabled || !this.props.onIconClick) {
      return
    }
    e.stopPropagation()
    this.props.onIconClick()
  }

  render() {
    const {
      color,
      width,
      disabled,
      icon,
      isIconRight,
      name,
      className,
      title,
      tooltip,
      isLoading,
      ...rest
    } = this.props

    const iconNode = icon && (
      <Icon data-testid='icon' onClick={this.onIconClick} icon={icon} />
    )
    return (
      <button
        title={title}
        disabled={disabled}
        className={cn('button', color, width, className, { tooltip }, isLoading && 'is-loading')}
        onClick={() => this.onClick()}
        data-tooltip={tooltip}
        {...rest}
      >
        {!isIconRight && iconNode}
        {name && <span>{name}</span>}
        {isIconRight && iconNode}
      </button>
    )
  }
}

Button.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isIconRight: PropTypes.bool,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onClick: PropTypes.func,
  width: PropTypes.func,
  onIconClick: PropTypes.func,
  title: PropTypes.string,
  tooltip: PropTypes.string,
}

Button.defaultProps = {
  className: '',
  isLoading: false,
}

export default Button
