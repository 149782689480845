import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Icon from 'components/Icon'

import css from './Sidebar.less'

class SidebarSearch extends React.Component {
  searchKeyDown = (e) => {
    if (e.key === 'Escape') {
      this.props.hide()
    }
  }

  render() {
    const { sidebarOpen, filterPhrase, toggleDomElement } = this.props

    return (
      <a
        onClick={() => !sidebarOpen && toggleDomElement('sidebarOpen')}
        className={cn(css.link, css.toggle, 'tooltip tooltip-is-right')}
        data-tooltip='Search'
      >
        <Icon
          size='lg'
          onClick={() => sidebarOpen && toggleDomElement('sidebarOpen')}
          icon={sidebarOpen ? 'search' : 'bars'}
          className={css.icon}
        />
        {sidebarOpen && (
          <input
            autoFocus
            className={css.searchInput}
            placeholder='Search...'
            onKeyDown={this.searchKeyDown}
            onChange={this.props.editSearch}
            value={filterPhrase}
          />
        )}
      </a>
    )
  }
}

SidebarSearch.propTypes = {
  toggleDomElement: PropTypes.func,
  editSearch: PropTypes.func,
  hide: PropTypes.func,
  sidebarOpen: PropTypes.bool,
  filterPhrase: PropTypes.string,
}

export default SidebarSearch
