import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import Toast from 'components/Toast'
import Button from 'components/Button'
import Input from 'components/Input'
import Message from 'components/Message'

import app from 'core/app'
import user from 'core/user'

import PortalFunctions from '@mfg/cloud/functions/portal'

const DEFAULT_USER_MESSAGE =
  'Connect a default game user with your profile.'

class UserSettings extends Component {
  change = (type, settings) => {
    this.props.onEditSettings(type, settings)
  }

  setDefaultAccount = async () => {
    const {
      loggedUser: { username },
      settings: { defaultUser },
    } = this.props
    try {
      await PortalFunctions.editUser(username + '@decagames.com', {
        defaultUser: defaultUser,
      })
    } catch (err) {
      console.error(err)
      Toast.notify(`Error setting default account.`)
    }
  }

  render() {
    const { defaultUser } = this.props.settings
    const {
      loggedUser: { defaultUser: saved },
    } = this.props

    return (
      <div className='modal--default'>
        <h5 className='subtitle'>{'Default user'}</h5>
        <Message hasClose message={DEFAULT_USER_MESSAGE} />
        <div className='content'>
          {saved && (
            <p>
              User connected with your account: <strong>{saved}</strong>
            </p>
          )}
          <div className='field has-addons'>
            <Input
              placeholder='Enter uid'
              onEnter={this.setDefaultAccount}
              onChange={(val) => this.change('defaultUser', val)}
              value={defaultUser}
            />
            <Button
              className='is-primary'
              name='Save'
              onClick={this.setDefaultAccount}
            />
          </div>
        </div>
      </div>
    )
  }
}

UserSettings.propTypes = {
  loggedUser: PropTypes.object,
  settings: PropTypes.object,

  onEditSettings: PropTypes.func,
}

const mapStateToProps = (state) => ({
  settings: app.getSettings(state),
  loggedUser: user.getInfo(state),
})

const mapDispatchToProps = (dispatch) => ({
  onEditSettings: (...params) => dispatch(app.editSettings(...params)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserSettings),
)
