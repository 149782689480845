import { fromJS } from 'immutable'
import * as types from '../actions'

const docTypes = (state = {}, action) => {
  switch (action.type) {
    case types.CREATE_CMS_DOC_TYPE:
      return fromJS(state)
        .setIn([action.name, 'fields'], action.fields)
        .toJS()

    case types.DELETE_CMS_DOC_TYPE:
      return fromJS(state)
        .deleteIn([action.docTypeName])
        .toJS()

    case types.SET_CMS_DOC_TYPE_FIELDS:
      return fromJS(state)
        .setIn([action.docType, 'fields'], action.fields)
        .toJS()

    case types.SET_CMS_DOC_TYPE_LIMIT:
      return fromJS(state)
        .setIn([action.docType, 'maxItemCount'], action.limit)
        .toJS()

    default:
      return state
  }
}

const sections = (state = {}, action) => {
  switch (action.type) {
    case types.SET_CMS_SECTION_ACCESS:
      return fromJS(state)
        .setIn([action.sectionName, 'access'], action.access)
        .toJS()

    default:
      return state
  }
}

const meta = (state = {}, action) => {
  if (action.type === types.SET_CMS_META) {
    return action.data
  }
  if (action.type === types.CLEAR_CMS) {
    return {}
  }
  return {
    ...state,
    docTypes: docTypes(state.docTypes, action),
    sections: sections(state.sections, action),
  }
}

export default meta
