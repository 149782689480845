import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import qs from 'query-string'

import Toast from 'components/Toast'

import cloudCore from 'core/cloud'

const GetShareUrl = ({ children, ...props }) => {
  // get current url sections
  const { origin, pathname } = window.location
  const shareQuery = getShareQuery(props)
  const shareUrl = `${origin}${pathname}?${shareQuery}`
  return (
    <CopyToClipboard
      text={shareUrl}
      onCopy={() => Toast.notify('Url copied to clipboard!')}
    >
      {children}
    </CopyToClipboard>
  )
}

export const getShareQuery = ({ params, search, cloud }) => {
  const { cloudVer, appVer, prodId } = cloud
  // create single object with all possible params
  const pool = {
    cloudVer,
    appVer,
    prodId,
    search,
  }
  // parse current query into sane object
  const currentQuery = qs.parse(window.search)
  // add chosen parameters to query
  const newQuery = params.reduce(
    (final, param) => {
      if (!pool[param]) {
        return final
      }
      return {
        ...final,
        [param]:
          typeof pool[param] === 'object'
            ? JSON.stringify(pool[param])
            : pool[param],
      }
    },
    { ...currentQuery },
  )
  // return shareable url
  return qs.stringify(newQuery)
}

GetShareUrl.propTypes = {
  // shareable params
  cloud: PropTypes.object.isRequired,
  search: PropTypes.object.isRequired,
  // wanted params in url
  params: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
}

export default connect((state) => ({
  cloud: cloudCore.getCloud(state),
  search: state.support.userSearch.toolbar,
}))(GetShareUrl)
