import * as types from '../actions'

export const hasChanges = (state = false, action) => {
  switch (action.type) {
    case types.EDIT_CMS_BASIC_ELEMENT:
    case types.ADD_CMS_ITEM:
    case types.ADD_CMS_DOC_TYPE:
    case types.DELETE_CMS_SECTION:
    case types.DELETE_CMS_ITEM:
    case types.DELETE_CMS_DOC_TYPE:
    case types.SWITCH_CMS_ITEMS:
    case types.MOVE_CMS_ITEM_TOP:
    case types.CREATE_CMS_DOC_TYPE:
    case types.SET_CMS_DOC_TYPE_LIMIT:
    case types.SET_CMS_DOC_TYPE_FIELDS:
    case types.SET_CMS_SECTION_ACCESS:
    case types.EDIT_CMS_ITEM_TRANSLATION:
      return true

    case types.CLEAR_CMS:
    case types.CMS_PUBLISH_SUCCESS:
      return false

    default:
      return state
  }
}
