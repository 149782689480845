import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { render } from 'react-dom'
import ClickOutHandler from 'components/ClickOutHandler'
import _ from 'lodash'
import { css } from '@emotion/css'
import { createPortalRoot } from 'utils/misc'

const toastRoot = createPortalRoot('toast')

class Toast extends Component {
  constructor(props) {
    super(props)
    this.state = {
      className: 'down',
    }
    this.delay = this.props.text.length * 100 + 1500
  }

  componentDidMount() {
    this.setState({ className: '' })
    this.hideTimeout()
  }

  componentWillReceiveProps() {
    this.setState({ className: '' })
    this.hideTimeout()
  }

  hideTimeout = () => {
    this.timeout = window.setTimeout(() => {
      this.setState({ className: 'down' })
    }, this.delay)
  }

  onClickOut = () => {
    this.setState({ className: 'down' })
    window.clearTimeout(this.timeout)
  }

  render() {
    const { className } = this.state
    const { status, text } = this.props

    return (
      <ClickOutHandler onClickOut={this.onClickOut}>
        <div className={`${styles.wrap} ${styles[className]} animated fadeIn`}>
          <div className={`${styles.text} ${styles[status]}`}>{text}</div>
        </div>
      </ClickOutHandler>
    )
  }
}

Toast.propTypes = {
  status: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
}

Toast.notify = (notification = {}, status = '') => {
  let text = typeof notification === 'string' ? notification : 'Error'
  if (notification.result !== undefined) {
    text = notification.result
  }
  let textObject =
    notification.httpResult ||
    notification.res ||
    notification.response ||
    (notification.data
      ? !_.isEmpty(notification.data)
        ? notification.data
        : notification
      : undefined)

  if (typeof textObject === 'string') {
    text = textObject
    try {
      textObject = JSON.parse(textObject)
      if (textObject.httpResult) {
        textObject = textObject.httpResult
      }
    } catch (e) {}
  }

  if (notification instanceof Error) {
    text = notification.toString()
    status = 'error'
  }

  if (notification.httpResult === 'ok' || text === 'ok') {
    text = 'Success!'
    status = 'success'
  }

  // look for new response object and send erroror success message.
  if (
    notification.data &&
    typeof notification.data === 'object' &&
    !_.isEmpty(notification.data)
  ) {
    text = notification.data.error || 'Success!'
    status = notification.data.error ? 'error' : 'success'
  }

  if (text === 'Error' && (textObject || !_.isEmpty(notification))) {
    const { result, code } = textObject || notification

    if (parseInt(result || code, 10) === 200) {
      text = 'Success!'
      status = 'success'
    }
    if (parseInt(result || code, 10) === 417) {
      text = 'Expectation failed!'
      status = 'error'
    }
    if (parseInt(result || code, 10) === 503) {
      text = 'Server timeout.'
      status = 'error'
    }
    if (parseInt(result || code, 10) === 403) {
      text = 'Forbidden!'
      status = 'error'
    }
  }

  render(<div />, toastRoot)
  render(<Toast text={text} status={status} />, toastRoot)

  return status
}

const styles = {
  wrap: css`
    position: fixed;
    width: 100%;
    bottom: 20px;
    display: flex;
    justify-content: center;
    transition: all 0.2s ease;
    z-index: 15000;
  `,
  down: css`
    bottom: -100px;
  `,
  text: css`
    background-color: rgba(0, 0, 0, 0.95);
    border-radius: 3px;
    color: #fff;
    font-size: 21px;
    margin: auto;
    padding: 12px 20px;
    position: relative;
    text-align: center;
  `,
  error: css`
    background-color: rgb(172, 0, 0);
  `,
  warning: css`
    background-color: #f35c03;
  `,
}

export default Toast
