import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Button from './Button'

const Loading = ({ className }) => {
  return (
    <Button className={cn('is-loading is-toggle', className)} icon='cloud' />
  )
}

Loading.propTypes = {
  className: PropTypes.string,
}

export default Loading
