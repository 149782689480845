import { fromJS } from 'immutable'
import { combineReducers } from 'redux'

import {
  START_FETCH,
  STOP_FETCH,
  EDIT_SETTINGS,
  GENERAL_ERROR,
  TOGGLE_DOM_ELEMENT,
  TOGGLE_MODAL,
  SET_POPUP,
  SET_HEADER,
  APP_STOP_INIT,
  SHOW_LOGGED_USER,
  HIDE_LOGGED_USER,
} from './actions'

const init = {
  initializing: true,
  requests: [],
  header: {
    cloudVer: false,
    prodId: false,
    appVer: false,
    loggedUser: true,
  },
  dom: {},
}

const requests = (state, action) => {
  switch (action.type) {
    case START_FETCH: {
      const requestAlreadyAdded =
        state.find((req) => {
          if (action.request.id) {
            return action.request.id === req.id
          }
          return req.url === action.request.url
        }) !== undefined
      return requestAlreadyAdded ? state : [...state, action.request]
    }


    case STOP_FETCH: {
      // remove request with same id or url
      return state.filter((req) => {
        if (action.request.id) {
          return req.id !== action.request.id
        }
        return req.url !== action.request.url
      })
    }

    default:
      return state
  }
}

const initSettings = {
  defaultUser: '',
}

const settings = (state = initSettings, action) => {
  switch (action.type) {
    case EDIT_SETTINGS:
      return fromJS(state)
        .set(action.section, action.settings)
        .toJS()

    default:
      return state
  }
}

const errors = (state = {}, action) => {
  switch (action.type) {
    case GENERAL_ERROR:
      return {
        ...state,
        general: action.err,
      }

    default:
      return state
  }
}

const elements = (state = {}, action) => {
  if (action.type === TOGGLE_DOM_ELEMENT) {
    return {
      ...state,
      [action.path]: !state[action.path],
    }
  }
  return state
}

const modal = (state = {}, action) => {
  if (action.type === TOGGLE_MODAL) {
    return {
      ...state,
      [action.modalId]:
        action.isVisible !== undefined
          ? action.isVisible
          : !state[action.modalId],
    }
  }
  return state
}

const popup = (state = {}, action) => {
  if (action.type === SET_POPUP) {
    return action.options
  }
  return state
}

const dom = combineReducers({
  elements,
  modal,
  popup,
})

// TODO: refactor to use combineReducers
export default (state = init, action) => {
  switch (action.type) {
    case SET_HEADER:
      return {
        ...state,
        header: action.options,
      }

    case APP_STOP_INIT:
      return {
        ...state,
        initializing: false,
      }

    case SHOW_LOGGED_USER:
      return {
        ...state,
        header: { ...state.header, loggedUser: true },
      }

    case HIDE_LOGGED_USER:
      return {
        ...state,
        header: { ...state.header, loggedUser: false },
      }

    default:
      return {
        ...state,
        requests: requests(state.requests, action),
        settings: settings(state.settings, action),
        errors: errors(state.errors, action),
        dom: dom(state.dom, action),
      }
  }
}
