import { NAME } from './constants'

export const FETCH_TOP_REPORTED_USERS = `${NAME}/FETCH_TOP_REPORTED_USERS`
export const CLEAR_TOP_REPORTED_USERS = `${NAME}/CLEAR_TOP_REPORTED_USERS`
export const CHANGE_REPORT_GROUP = `${NAME}/CHANGE_REPORT_GROUP`
export const TOGGLE_SHOW_BANNED = `${NAME}/TOGGLE_SHOW_BANNED`

export const toggleShowBanned = () => ({
  type: TOGGLE_SHOW_BANNED,
})
