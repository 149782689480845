import { NAME } from './constants'

export const ADMIN_GET_ATTRIBUTES = `${NAME}/ADMIN_GET_ATTRIBUTES`
export const ADMIN_ADD_ATTRIBUTE = `${NAME}/ADMIN_ADD_ATTRIBUTE`
export const ADMIN_REMOVE_ATTRIBUTE = `${NAME}/ADMIN_REMOVE_ATTRIBUTE`
export const REMOVE_ALL_ATTRIBUTES = `${NAME}/REMOVE_ALL_ATTRIBUTES`
export const CHECK_INVALID_ATTRIBUTES = `${NAME}/CHECK_INVALID_ATTRIBUTES`
export const GET_ATTRIBUTES = `${NAME}/GET_ATTRIBUTES`
export const ADD_ATTRIBUTE = `${NAME}/ADD_ATTRIBUTE`
export const REMOVE_ATTRIBUTE = `${NAME}/REMOVE_ATTRIBUTE`
export const SET_USERID = `${NAME}/SET_USERID`
export const SET_ATTR_USER_DATA = `${NAME}/SET_ATTR_USER_DATA`

export const checkInvalidAttributes = (groups, admin, user) => {
  let invalid = []

  groups.forEach((group) => {
    user[group].forEach((name) => {
      if (admin[group].indexOf(name) < 0) {
        invalid.push({ name, group })
      }
    })
  })
  return {
    type: CHECK_INVALID_ATTRIBUTES,
    invalid,
  }
}

export const setUserId = (userid) => ({
  type: SET_USERID,
  userid,
})
