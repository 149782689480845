import * as types from '../actions'

const SUPPORTED_LOCALES = ['de', 'fr', 'zh', 'cs', 'es', 'it', 'ja', 'ko', 'pt', 'ru', 'tr']

const init = {
  locale: '',
  docType: null,
  supportedLocales: SUPPORTED_LOCALES,
  selectedItemPath: [],
  splitMode: false,
  isSidebarOpen: true,
}

const other = (state = init, action) => {
  switch (action.type) {
    case types.CLEAR_CMS:
      return init

    case types.SET_CMS_PRODUCT:
      return {
        ...state,
        supportedLocales: action.locales,
        locale: action.locales[0],
      }

    case types.CHANGE_CMS_LOCALE:
      return {
        ...state,
        locale: action.locale,
      }

    case types.SELECT_CMS_ITEM:
      return {
        ...state,
        selectedItemPath: action.path,
      }

    case types.SELECT_CMS_DOC_TYPE:
      return {
        ...state,
        docType: action.docType,
      }
    case types.TOGGLE_CMS_GUI:
      return {
        ...state,
        [action.prop]: !state[action.prop],
      }

    default:
      return state
  }
}

export default other
