import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import hoistNonReactStatic from 'hoist-non-react-statics'

import app from 'core/app'
import cloud from 'core/cloud'

import { getDisplayName } from 'utils/hoc'
import { didCloudChange } from 'utils/misc'
import { withIsFetching } from './hoc'

// Injects cloud reducer as prop, if wrapped component has
// onCloudChange method it triggers it on cloud change :)

const withCloud = (header, fetchSettings, fetchTexts, noCache) => (
  WrappedComponent,
) => {
  class EnhancedComponent extends Component {
    static displayName = `WithCloud(${getDisplayName(WrappedComponent)})`

    componentDidMount = () => {
      this.props.setHeader(header)
      this.fetchProductSettings()
    }

    componentDidUpdate = (prevProps) => {
      const { cloud: next } = this.props
      const { cloud: prev } = prevProps

      if (didCloudChange(prev, next)) {
        // clear settings on cloud change
        if (prev.cloudVer !== next.cloudVer) {
          this.props.clearProductSettings()
        }
        this.fetchProductSettings()

        if (this.__wrappedInstance && typeof this.__wrappedInstance.onCloudChange === 'function') {
          this.__wrappedInstance.onCloudChange(next, prev)
        }
      }
    }

    componentWillUnmount = () => {
      this.props.unsetHeader(header)
    }

    fetchProductSettings = () => {
      if (fetchSettings === true) {
        this.props.loadProductSettings({ noCache })
      }
      if (Array.isArray(fetchSettings)) {
        this.props.loadProductSettings(fetchSettings, { noCache })
      }
      if (fetchTexts) {
        this.props.loadTexts()
      }
    }

    getRef = () => {
      // assign ref only to class components
      if (WrappedComponent.prototype && WrappedComponent.prototype.render) {
        return (c) => {
          this.__wrappedInstance = c
        }
      }
    }

    render() {
      return (
        <WrappedComponent {...this.props} ref={this.getRef()} />
      )
    }
  }

  EnhancedComponent.propTypes = {
    cloud: PropTypes.object.isRequired,
    clearProductSettings: PropTypes.func.isRequired,
    loadProductSettings: PropTypes.func.isRequired,
    loadTexts: PropTypes.func.isRequired,
    setHeader: PropTypes.func.isRequired,
    unsetHeader: PropTypes.func.isRequired,
  }

  return hoistNonReactStatic(
    connect(
      stateToProps,
      actionsToProps,
    )(EnhancedComponent),
    WrappedComponent,
  )
}

const stateToProps = (state) => {
  const { prodId, appVer } = cloud.getCloud(state)
  return {
    cloud: cloud.getCloud(state),
    settings: state.core.cloud.settings[prodId][appVer],
    texts: state.core.cloud.texts[prodId] && state.core.cloud.texts[prodId][appVer],
    ...withIsFetching(state),
  }
}

const actionsToProps = (dispatch) =>
  bindActionCreators(
    {
      setHeader: app.setHeader,
      unsetHeader: app.unsetHeader,
      ...cloud,
    },
    dispatch,
  )

export default withCloud
