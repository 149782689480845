
import { NAME, ALLOWED_CLOUD_PROPS } from './constants'

// action types

export const SET_CLOUD_PROP = `${NAME}/SET_CLOUD_PROP`
export const SET_APP_VERS = `${NAME}/SET_APP_VERS`
export const SET_PERMISSIONS = `${NAME}/SET_PERMISSIONS`
export const SET_CLOUD_CREDENTIALS = `${NAME}/SET_CLOUD_CREDENTIALS`

// product/version settings types

export const START_SETTINGS_FETCH = `${NAME}/START_SETTINGS_FETCH`
export const STOP_SETTINGS_FETCH = `${NAME}/STOP_SETTINGS_FETCH`
export const LOAD_PRODUCT_VERSIONED = `${NAME}/LOAD_PRODUCT_VERSIONED`
export const LOAD_TEXTS = `${NAME}/LOAD_TEXTS`
export const LOAD_REGIONS = `${NAME}/LOAD_REGIONS`
export const CLEAR_PRODUCT_SETTINGS = `${NAME}/CLEAR_PRODUCT_SETTINGS`
export const SET_PRODUCT_SETTINGS = `${NAME}/SET_PRODUCT_SETTINGS`
export const SET_TEXTS = `${NAME}/SET_TEXTS`

// action creators

export const setCloudProp = (propName, propValue) => {
  // set only allowed cloud props
  if (!ALLOWED_CLOUD_PROPS.includes(propName)) {
    console.log(`Cloud prop ${propName} not allowed for change.`)
    return undefined
  }
  return {
    type: SET_CLOUD_PROP,
    propName,
    propValue,
  }
}

export const setAppVer = (appVer) => setCloudProp('appVer', appVer)

export const clearProductSettings = () => ({
  type: CLEAR_PRODUCT_SETTINGS,
})

// DEPRECATED
export const loadVersionedSettings = (prodId, data) => ({
  type: LOAD_PRODUCT_VERSIONED,
  data,
})
