import 'whatwg-fetch'
import React from 'react'
import { render } from 'react-dom'

import Root from './components/root/Root'

import 'styles/styles.less'
import 'styles/bulma/bulma.sass'

// open links from desk.com to this window
window.name = 'madfingergames-admin'

const renderApp = (Component) =>
  render(<Component />, document.getElementById('root'))

renderApp(Root)

if (module.hot) {
  // Any changes to our App will cause a hotload re-render.
  module.hot.accept('./components/root/Root', () => {
    renderApp(Root)
  })
}
