import { NAME } from './constants'

export const LEGENDS_FETCH_START = `${NAME}/LEGENDS_FETCH_START`
export const LEGENDS_FETCH_STOP = `${NAME}/LEGENDS_FETCH_STOP`

export const SET_LANGUAGE_LIST = `${NAME}/SET_LANGUAGE_LIST`
export const SET_LOCALE = `${NAME}/SET_LOCALE`
export const SET_BAD_WORDS = `${NAME}/SET_BAD_WORDS`
export const SET_ORIGINAL_BAD_WORDS = `${NAME}/SET_ORIGINAL_BAD_WORDS`
export const ADD_BAD_WORD = `${NAME}/ADD_BAD_WORD`
export const REMOVE_BAD_WORD = `${NAME}/REMOVE_BAD_WORD`

export const LEGENDS_FETCH_DEVICES = `${NAME}/LEGENDS_FETCH_DEVICES`
export const LEGENDS_FETCH_DEVICE_INFO = `${NAME}/LEGENDS_FETCH_DEVICE_INFO`
export const LEGENDS_SELECT_DEVICE = `${NAME}/LEGENDS_SELECT_DEVICE`
export const LEGENDS_CHANGE_RECORDS = `${NAME}/LEGENDS_CHANGE_RECORDS`
export const LEGENDS_TOGGLE_VISIBLE = `${NAME}/LEGENDS_TOGGLE_VISIBLE`

export const WALK_OF_FAME_SET = `${NAME}/WALK_OF_FAME_SET`
export const WALK_OF_FAME_ADD = `${NAME}/WALK_OF_FAME_ADD`
export const WALK_OF_FAME_REMOVE = `${NAME}/WALK_OF_FAME_REMOVE`
export const WALK_OF_FAME_DRAG = `${NAME}/WALK_OF_FAME_DRAG`
export const WALK_OF_FAME_TOGGLE_EDIT = `${NAME}/WALK_OF_FAME_TOGGLE_EDIT`
export const WALK_OF_FAME_DISCARD = `${NAME}/WALK_OF_FAME_DISCARD`
export const WALK_OF_FAME_SAVE = `${NAME}/WALK_OF_FAME_SAVE`
export const WALK_OF_FAME_FETCH = `${NAME}/WALK_OF_FAME_FETCH`

export const SET_GUILDS_LIST = `${NAME}/SET_GUILDS_LIST`
export const SET_GUILD_DETAIL = `${NAME}/SET_GUILD_DETAIL`
export const EDIT_GUILD_DETAIL = `${NAME}/EDIT_GUILD_DETAIL`
export const REMOVE_GUILD_MEMBER = `${NAME}/REMOVE_GUILD_MEMBER`
export const PROMOTE_GUILD_MEMBER = `${NAME}/PROMOTE_GUILD_MEMBER`
