import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import PropTypes from 'prop-types'
import clickOut from 'react-click-outside'
import cn from 'classnames'
import _ from 'lodash'

import app from 'core/app'

import SidebarLink from './SidebarLink'
import SidebarSearch from './SidebarSearch'

import sections from './sections'

import storage from '@mfg/common/utils/storage'
import hasAuth from 'utils/auth/hasAuth'

import css from './Sidebar.less'

class Sidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filterPhrase: '',
      pinned: storage.get('sidebar-pinned') || [],
    }
  }

  handleClickOutside = () => {
    if (this.props.sidebarOpen) {
      this.hide()
    }
  }

  hide = () => {
    this.props.toggleDomElement('sidebarOpen')
    this.setState({
      filterPhrase: '',
    })
  }

  containsPhrase = (object) =>
    JSON.stringify(object)
      .toLowerCase()
      .indexOf(this.state.filterPhrase.toLowerCase()) >= 0

  filterSections = () => {
    const { filterPhrase } = this.state

    if (!filterPhrase) {
      return sections
    }

    return Object.keys(sections).reduce((filteredSections, sectionName) => {
      const section = sections[sectionName]

      if (this.containsPhrase(section)) {
        let filteredSection = section

        if (!this.containsPhrase([sectionName, section.title])) {
          filteredSection = {
            ...section,
            links: section.links.filter(this.containsPhrase),
          }
        }
        return {
          ...filteredSections,
          [sectionName]: filteredSection,
        }
      }
      return filteredSections
    }, {})
  }

  editSearch = (e) => this.setState({ filterPhrase: e.currentTarget.value })

  togglePin = (section) => (link) => {
    const { pinned } = this.state
    const pin = `${section}.${link}`
    let newPinned
    if (pinned.includes(pin)) {
      newPinned = pinned.filter((p) => p !== pin)
    } else {
      newPinned = [...pinned, pin]
    }
    storage.set('sidebar-pinned', newPinned)
    this.setState({ pinned: newPinned })
  }

  render() {
    const {
      sidebarOpen,
      loggedUser: { permissions },
    } = this.props
    const { filterPhrase, pinned } = this.state
    const filteredSections = this.filterSections()
    const hasAdminPermissions =
      permissions.filter((perm) => perm.startsWith('admin')).length > 0

    return (
      <div className={cn(css.wrap, sidebarOpen && css.sidebarOpen)}>
        <section>
          <SidebarSearch
            {...this.props}
            editSearch={this.editSearch}
            hide={this.hide}
            filterPhrase={filterPhrase}
          />

          {!filterPhrase && (
            <SidebarLink
              {...this.props}
              to='/'
              icon='columns'
              label='Dashboard'
            />
          )}

          {!filterPhrase &&
            pinned.length > 0 &&
            pinned.map((pin) => {
              const [sectionName, to] = pin.split('.')
              const link = filteredSections[sectionName].links.find(
                (link_local) => link_local.to === to,
              )
              // pinned link was removed from sidebar
              if (!link) {
                return null
              }
              return (
                <SidebarLink
                  key={`sectionLink-${link.to}`}
                  {...this.props}
                  {...link}
                />
              )
            })}
        </section>

        {_.map(filteredSections, (section, sectionName) => {
          // don't show admin section if user has no admin permissions
          if (sectionName === 'admin' && !hasAdminPermissions) {
            return null
          }
          return (
            <section key={`section-${sectionName}`}>
              <div className={css.sectionTitle}>{section.title}</div>
              {section.links.map((link) => {
                const hasPermissions =
                  !link.permission || hasAuth(link.permission)
                const isPinned = pinned.includes(`${sectionName}.${link.to}`)

                if (!sidebarOpen && (!hasPermissions || isPinned)) {
                  return null
                }
                return (
                  <SidebarLink
                    key={`sectionLink-${link.to}`}
                    {...this.props}
                    {...link}
                    disabled={!hasPermissions}
                    isPinned={isPinned}
                    togglePin={this.togglePin(sectionName)}
                  />
                )
              })}
            </section>
          )
        })}
      </div>
    )
  }
}

Sidebar.propTypes = {
  toggleDomElement: PropTypes.func,
  loggedUser: PropTypes.object,
  sidebarOpen: PropTypes.bool,
}

export default compose(
  connect(
    (state) => state.core.app.dom.elements,
    (dispatch) => bindActionCreators(app, dispatch),
  ),
  clickOut,
)(Sidebar)
