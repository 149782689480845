import { NAME } from './constants'

export const START_VOUCHER_FETCH = `${NAME}START_VOUCHER_FETCH`
export const STOP_VOUCHER_FETCH = `${NAME}STOP_VOUCHER_FETCH`
export const SET_VOUCHERS = `${NAME}SET_VOUCHERS`
export const EDIT_VOUCHER = `${NAME}EDIT_VOUCHER`
export const EDIT_VOUCHERS_FILTER = `${NAME}EDIT_VOUCHERS_FILTER`
export const EDIT_VOUCHERS_SORT = `${NAME}EDIT_VOUCHERS_SORT`
export const EDIT_NEW_VOUCHER = `${NAME}EDIT_NEW_VOUCHER`
export const FETCH_REWARDS = `${NAME}FETCH_REWARDS`
export const EDIT_REWARDS_ID = `${NAME}EDIT_REWARDS_ID`
export const TOGGLE_FETCH_VOUCHER_CODES = `${NAME}TOGGLE_FETCH_VOUCHER_CODES`
export const RESET_NEW_VOUCHER = `${NAME}RESET_NEW_VOUCHER`

export const setVouchers = (vouchers) => ({
  type: SET_VOUCHERS,
  vouchers,
})

// merges "data" object with current voucher object
export const editVoucher = (name, data) => ({
  type: EDIT_VOUCHER,
  name,
  data,
})

export const editFilter = (filter, value) => ({
  type: EDIT_VOUCHERS_FILTER,
  filter,
  value,
})

export const editSort = (property) => ({
  type: EDIT_VOUCHERS_SORT,
  property,
})

export const editNewVoucher = (prop, value) => ({
  type: EDIT_NEW_VOUCHER,
  prop,
  value,
})

export const resetNewVoucher = () => ({
  type: RESET_NEW_VOUCHER,
})

export const editVoucherRewards = (rewardsId, rewardsList) => ({
  type: EDIT_REWARDS_ID,
  rewardsId,
  rewardsList,
})
