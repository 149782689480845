import React from 'react'

import Tiles from './Tiles'

// import css from './Dashboard.less'

const Dashboard = () => (
  <div className='main-view'>
    <div className='title'>Dashboard</div>
    <Tiles />
  </div>
)

export default Dashboard
