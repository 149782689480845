import { NAME } from './constants'

export const ADD_LOGS = `${NAME}/ADD_LOGS`
export const CLEAR_LOGS = `${NAME}/CLEAR_LOGS`

export function addLogs(logs) {
  return {
    type: ADD_LOGS,
    logs,
  }
}

export function clearLogs() {
  return {
    type: CLEAR_LOGS,
  }
}
