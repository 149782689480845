import AuthFunctions from '@mfg/cloud/functions/auth'

import { loginSuccess, loginFail } from './actions'

/**
 * Fetch info about the currently logged in user
 */
export const loginUser = () => async (dispatch) => {
  try {
    const res = await AuthFunctions.me()
    if (res.status === 200) {
      dispatch(loginSuccess(res.data.user))
      return res.data
    }
    throw new Error(`Auth service failed. ${res && res.err}`)
  } catch (err) {
    console.log('Login failed', err)
    dispatch(loginFail())
    return false
  }
}
