import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Route, Switch } from 'react-router-dom'
import { hot } from 'react-hot-loader/root'

import Unauthorized from './Unauthorized'
import LoggedOut from './LoggedOut'
import NewVersion from './NewVersion'
import Header from './Header'
import Sidebar from './Sidebar'
import NotFound from './NotFound'
import Initializing from './Initializing'

import Popup from 'components/Popup'
import Error from 'components/Error'

import Dashboard from 'sections/dashboard'
import Admin from 'sections/admin'
import Support from 'sections/support'
import Games from 'sections/games'
import Marketing from 'sections/marketing'
import Web from 'sections/web'
import MfgCloud from 'sections/mfgcloud'
import Development from 'sections/development'

import app from 'core/app'
import user from 'core/user'

import catchErrors from 'utils/catchErrors'
import withLogin from '@mfg/components/hoc/withLogin'

class App extends Component {
  componentDidMount() {
    this.props.dispatch(app.init())
  }

  render() {
    const { loggedUser, initializing, error } = this.props

    if (error) {
      return <Error error={error} />
    }

    if (initializing) {
      return <Initializing />
    }

    if (loggedUser.loggedOut) {
      return <LoggedOut />
    }

    if (loggedUser.unauthorized) {
      return <Unauthorized />
    }

    return (
      <Fragment>
        <div className='app'>
          <Sidebar {...this.props} />

          <Header {...this.props} />

          <main>
            <Switch>
              <Route exact path='/' component={Dashboard} />

              <Route path='/dashboard' component={Dashboard} />

              <Route path='/admin' component={Admin} />

              <Route path='/support' component={Support} />

              <Route path='/games' component={Games} />

              <Route path='/marketing' component={Marketing} />

              <Route path='/web' component={Web} />

              <Route path='/mfgcloud' component={MfgCloud} />

              <Route path='/development' component={Development} />

              <Route exact path='*' component={NotFound} />
            </Switch>
          </main>
        </div>

        {/* NOTE: Need to include Popup in the dom as it is internally just another Modal */}
        <Popup />

        <NewVersion />
      </Fragment>
    )
  }
}

const stateToProps = (state) => {
  return {
    initializing: app.getInitializing(state),
    loggedUser: user.getInfo(state),
    error: app.getErrors(state).general,
  }
}
App.propTypes = {
  initializing: PropTypes.bool.isRequired,

  error: PropTypes.string,
  loggedUser: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
}
export default compose(
  withLogin,
  withRouter,
  connect(stateToProps),
  catchErrors,
)(hot(App))
