import * as types from './actions'

const init = {
  list: [],
  listMeta: {},
  allCampaigns: [],
}

const contacts = (state = init, action) => {
  switch (action.type) {
    case types.SEARCH_CONTACTS:
      return {
        ...state,
        list: action.contacts,
        listMeta: {},
      }

    case types.FETCH_CONTACTS:
      return {
        ...state,
        list: [...state.list, ...action.contacts],
        listMeta: action.listMeta,
      }

    case types.CLEAR_CONTACTS:
      return {
        ...state,
        list: [],
        listMeta: {},
      }

    case types.DELETE_CONTACT:
      return {
        ...state,
        list: state.list.filter((c) => c.email !== action.email),
      }

    case types.UNSUBSCRIBE_CONTACT:
      return {
        ...state,
        list: state.list.map((contact) => {
          if (contact.email !== action.email) {
            return contact
          }
          return {
            ...contact,
            campaigns: contact.campaigns.filter(c => c !== action.campaign),
          }
        }),
      }

    case types.SET_ALL_CAMPAIGNS:
      return {
        ...state,
        allCampaigns: action.campaigns,
      }

    default:
      return state
  }
}

export default contacts
