import { fromJS } from 'immutable'
import storage from '@mfg/common/utils/storage'

import * as types from './actions'

const initial = fromJS({
  arenaId: '',
  list: [],
  fetchInfo: '',
  available: [],
  username: '',
  offset: 1,
  limit: '',
  showPlatform: storage.get('showLdbPlatform') !== 'false',
  showReports: storage.get('showLdbReports') !== 'false',
  showUsernames: storage.get('showLdbUsernames') === 'true',
  fetching: false,
})

export default (state = initial, action) => {
  switch (action.type) {
    case types.START_FETCH_LDB:
      return (
        state
          // clear list on fetch, new list is going to replace it
          // .set('list', [])
          .set('fetching', true)
      )

    case types.FETCH_LEADERBOARD:
      return state
        .set('list', action.list)
        .set('fetching', false)
        .set('fetchInfo', action.fetchInfo)

    case types.FETCH_USER_LEADERBOARD:
      return state
        .set(action.arenaId, action.data)
        .set('fetching', false)
        .set('fetchInfo', action.fetchInfo)

    case types.UPDATE_LEADERBOARD:
      return state.update('list', (list) => {
        return list.map((user, i) => ({
          ...user,
          ...action.list[i],
        }))
      })

    case types.EDIT_SCORE:
      return state.set(action.kind, {
        ...state.get(action.kind),
        score: parseInt(action.val, 10) || 0,
      })

    case types.SET_PROP:
      return state.merge(action.property)

    case types.DELETE_LDB_USER:
      return state.update('list', (list) =>
        list.filter((user) => user.userID !== action.userId),
      )

    default:
      return state
  }
}
