import { fromJS } from 'immutable'
import {
  CLEAR_SEARCH,
  EDIT_SEARCH,
  ADD_USERS,
  UPDATE_USERS,
  START_SEARCH,
  SEARCH_DONE,
  TOGGLE_FETCH_ALL,
  SET_SORT,
} from '../types'

const init = {
  foundUsers: [],
  searchValue: '',
  selected: [],
  fetching: false,
  searched: false,
  fetchingAll: false,
  sortBy: [], // ['uid', 'asc']
}

export const general = (state = init, action) => {
  switch (action.type) {
    case CLEAR_SEARCH:
      return fromJS(state)
        .merge(
          fromJS({
            foundUsers: [],
            selected: [],
          }),
        )
        .toJS()

    case EDIT_SEARCH:
      return fromJS(state)
        .setIn(
          action.path,
          typeof action.val === 'object' ? fromJS(action.val) : action.val,
        )
        .toJS()

    case ADD_USERS:
      return fromJS(state)
        .update('foundUsers', (foundUsers) =>
          foundUsers.concat(fromJS(action.users)),
        )
        .toJS()

    case UPDATE_USERS: {
      const newUsers = action.users.reduce((finalUsers, userUpdate) => {
        const withSameUid = (user) => user.uid === userUpdate.uid
        const userIndex = finalUsers.findIndex(withSameUid)

        if (userIndex < 0) {
          return [...finalUsers, userUpdate]
        }

        finalUsers[userIndex] = {
          ...finalUsers[userIndex],
          ...userUpdate,
        }

        return finalUsers
      }, Array.from(state.foundUsers))

      return {
        ...state,
        foundUsers: newUsers,
      }
    }

    case START_SEARCH:
      return {
        ...state,
        fetching: true,
        searched: true,
      }

    case SEARCH_DONE:
      return {
        ...state,
        fetching: false,
      }

    case TOGGLE_FETCH_ALL:
      return {
        ...state,
        fetchingAll: !state.fetchingAll,
      }

    case SET_SORT:
      return fromJS(state)
        .updateIn(['sortBy'], (sortBy) => {
          const oldSort = sortBy.toJS()
          // to string in case new sort is an array
          const isSameSort =
            (oldSort[0] || '').toString() === action.sortBy.toString()
          // if no sort or different sort or same sort but reverse order
          if (
            !sortBy.size ||
            !isSameSort ||
            (isSameSort && oldSort[1] === 'desc')
          ) {
            return [action.sortBy, 'asc']
          }
          return [action.sortBy, 'desc']
        })
        .toJS()

    default:
      return state
  }
}
