import produce from 'immer'

import * as types from '../actions'

const translations = (state = {}, action) => produce(state, (draft) => {
  switch (action.type) {
    case types.CLEAR_CMS: {
      return {}
    }
    case types.ADD_CMS_ITEM_TRANSLATION: {
      const { locale, itemId, data } = action
      draft[itemId] = draft[itemId] || {}
      draft[itemId][locale] = data
      break
    }

    case types.EDIT_CMS_ITEM_TRANSLATION: {
      const { locale, itemId, propName, value } = action
      draft[itemId][locale][propName] = value
      break
    }
  }
})

export default translations
