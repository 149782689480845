import { Component } from 'react'
import PropTypes from 'prop-types'
import clickOut from 'react-click-outside'

class ClickOutHandler extends Component {
  handleClickOutside = () => {
    this.props.onClickOut()
  }

  render() {
    return this.props.children
  }
}

ClickOutHandler.propTypes = {
  children: PropTypes.node.isRequired,
  onClickOut: PropTypes.func.isRequired,
}

export default clickOut(ClickOutHandler)
