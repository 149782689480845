import _ from 'lodash'
import store from 'redux/store'
import app from 'core/app'
import cloud from 'core/cloud'
import loggedUser from 'core/user'

const PROJECT_IDS = {
  GlobalChat: 'globalchat',
  Legends: 'sg-legends',
  Unkilled: 'unkilled',
}

const PRODUCT_IDS = {
  Legends: 'sgl',
}

export const progressStart = (url, id) => {
  store.dispatch(app.requestStart({ url, id }))
}

export const progressStop = (url, id) => {
  store.dispatch(app.requestStop({ url, id }))
}

export const download = (filename, text) => {
  var pom = document.createElement('a')
  pom.setAttribute(
    'href',
    'data:text/plaincharset=utf-8,' + encodeURIComponent(text),
  )
  pom.setAttribute('download', filename)

  if (document.createEvent) {
    var event = document.createEvent('MouseEvents')
    event.initEvent('click', true, true)
    pom.dispatchEvent(event)
  } else {
    pom.click()
  }
}

export const analyze = (arenas) => {
  const setups = getEachPlayerSetup(arenas)
  return getSetupStats(setups)
}

const getSetupStats = (setups) => {
  let stats = {}
  _.forEach(setups, (arena, id) => {
    stats[id] = {}
    _.forEach(arena, (setup) => {
      const curSetup = _.sortBy(_.map(setup, (weapon) => weapon.name))
      stats[id][curSetup] = (stats[id][curSetup] || 0) + 1
    })
  })
  return stats
}

const getEachPlayerSetup = (arenas) => {
  let data = {}
  arenas.forEach((arena) => {
    data[arena.arenaId] = {}
    arena.top10.forEach((player) => {
      // get players best attempt in his logs
      if (!player.logs.length) {
        return
      }
      const bestAttempt = player.logs.reduce((col, log) => {
        const curScore = col.RawDataFromClient.Statistics.Score
        const newScore = log.RawDataFromClient.Statistics.Score
        if (newScore > curScore) {
          return log
        }
        return col
      })
      // get his equip from this attempt
      const setup = bestAttempt.RawDataFromClient.Items.filter(
        (item) =>
          item.ItemID.indexOf('Weapon') > -1 &&
          item.ItemID.indexOf('Blueprint') < 0 &&
          item.Location.indexOf('Equip') > -1,
      ).map((item) => ({
        name: item.ItemID.replace('Weapon.', ''),
        kills: item.Kills,
      }))

      data[arena.arenaId][player.userID] = setup
    })
  })
  return data
}

// params - anything that is sent as parameters to cloud
// endpoint - url of the request
// version - cloud version f.e. Production, Development
// level: 0 - Low, 1 - Medium, 2 - High
export const logRequest = (params, endpoint, module, version, level) => {
  return fetch('https://madfingergames-admin.appspot.com/log', {
    method: 'post',
    headers: new Headers({
      'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
    }),
    body:
      'param=' +
      JSON.stringify({
        user: loggedUser.getInfo(store.getState()).username,
        cmd: (params || {}).cmd || endpoint,
        param: _.omit(params, 'params', 'pw'),
        module,
        level,
        version,
        pw: cloud.getCloud(store.getState()).passwords.Internal,
      }),
  })
}

const verToNum = (ver = '') => parseInt(ver.replace(/\./g, ''), 10)

// access Redux store for necessary product data
const getProductDataFromStore = (forceProdId) => {
  let { cloudVer, appVer, prodId, appVers, products, services } =
    cloud.getCloud(store.getState())

  const projectId = PROJECT_IDS[forceProdId || prodId]
  // if desired product (f.e. Legends) not selected in the Cloud Settings, use latest version of the desired product
  if (forceProdId && forceProdId !== prodId) {
    if (projectId !== 'globalchat') {
      appVer = _.last(appVers[forceProdId][cloudVer])
    }
  }
  // if user detail fetched and user version higher than the one selected,
  // call endpoints at their current version of cloud
  // otherwise point at the version selected in Cloud Settings
  let { Version } = store.getState().support.sglUser.profile
  if (Version && verToNum(Version) > verToNum(appVer)) {
    appVer = Version
  }

  return {
    appVer,
    cloudVer,
    projectId,
    credentials: { products, services },
  }
}

// TODO: make injectConstructor implementation easier

// take a class and construct it with new params on every method call
export const injectConstructor = (ProxiedClass, prodId) => {
  const proxyHandler = {
    get: function (target, key) {
      // reduce number of called constructors by only calling it on the "request" method
      if (key === 'request') {
        const { credentials, projectId, cloudVer, appVer } =
          getProductDataFromStore(prodId)

        const cloudInfo = new ProxiedClass(
          credentials,
          projectId,
          cloudVer,
          appVer,
        )
        return cloudInfo[key]
      }
      return target[key]
    },
  }
  return new Proxy(new ProxiedClass(), proxyHandler)
}

function getCloudVer() {
  return cloud.getCloud(store.getState()).cloudVer
}

// take a class and return current cloudVer when accessed
export const injectWebserviceConstructor = (ProxiedClass) => {
  return new ProxiedClass(getCloudVer)
}

// take a class and return current cloudVer when accessed
export const injectNewhopeConstructor = (ProxiedClass) => {
  return new ProxiedClass(getCloudVer)
}

export const getProductId = (forceProdId, forceCloudVer) => {
  const cloudInfo = cloud.getCloud(store.getState())
  const prodId = forceProdId || cloudInfo.prodId
  const cloudVer = forceCloudVer || cloudInfo.cloudVer
  let product = 'mfg-product-' + PRODUCT_IDS[prodId]
  if (cloudVer.toLowerCase() === 'development') {
    product += '-dev'
  }
  return product
}
