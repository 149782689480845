import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import Link from 'components/Link'
import Icon from 'components/Icon'

export const DropdownItem = ({ icon, name, href, disabled, onClick }) => {
  const className = `dropdown-item ${disabled ? 'dropdown-item--disabled' : ''}`
  const children = (
    <Fragment>
      {icon && <Icon icon={icon} className='margin-right' />}
      <span>{name}</span>
    </Fragment>
  )
  if (href) {
    return (
      <Link className={className} to={href} onClick={onClick}>
        {children}
      </Link>
    )
  }
  return (
    <a className={className} onClick={onClick}>
      {children}
    </a>
  )
}
DropdownItem.propTypes = {
  name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  href: PropTypes.string,
}

export default DropdownItem
