export const FETCH_CONTACTS = 'FETCH_CONTACTS'
export const SEARCH_CONTACTS = 'SEARCH_CONTACTS'
export const DELETE_CONTACT = 'DELETE_CONTACT'
export const CLEAR_CONTACTS = 'CLEAR_CONTACTS'
export const SET_ALL_CAMPAIGNS = 'SET_ALL_CAMPAIGNS'
export const UNSUBSCRIBE_CONTACT = 'UNSUBSCRIBE_CONTACT'

export const clearContacts = () => ({
  type: CLEAR_CONTACTS,
})
