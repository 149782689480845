import _ from 'lodash'
import { fromJS } from 'immutable'

import { toggleListItem } from 'utils/misc'
import storage from '@mfg/common/utils/storage'

import {
  TOGGLE_FIELD,
  TOGGLE_PRODUCT,
  EDIT_RANGE,
  CLEAR_RANGE,
  SET_TOOLBAR,
} from '../types'

const INIT_LEGACY = [
  {
    name: 'Primary Key',
    field: 'uid',
    value: true,
  },
  {
    name: 'Username',
    field: 'Username',
    value: true,
  },
  {
    name: 'Email',
    field: 'Email',
    value: true,
  },
  {
    name: 'Nick',
    field: 'NickName',
    value: true,
  },
  {
    name: 'Google Play Games',
    field: 'Google',
    value: false,
  },
  {
    name: 'iOS Game Center',
    field: 'GameCenter',
    value: false,
  },
  {
    name: 'Unkilled FB',
    field: 'FacebookUnkilled',
    value: false,
  },
]
const INIT_SGL = [
  {
    name: 'Primary Key',
    field: 'uid',
    value: true,
  },
  {
    name: 'Nick',
    field: 'nickname',
    value: true,
  },
  {
    name: 'Email',
    field: 'mail',
    value: true,
  },
  {
    name: 'Developer',
    field: 'developer',
    value: false,
  },
  {
    name: 'Voucher',
    field: 'coupon',
    value: false,
  },
  {
    name: 'App Version',
    field: 'version',
    value: false,
  },
  {
    name: 'Rank',
    field: 'rank',
    value: [0, 0],
  },
  {
    name: 'Fame',
    field: 'fame',
    value: [0, 0],
  },
  {
    name: 'Money',
    field: 'money',
    value: [0, 0],
  },
  {
    name: 'Gold',
    field: 'gold',
    value: [0, 0],
  },
]

const INIT_SGWG = [
  {
    name: 'notype',
    field: 'notype',
    value: false,
  },
]

const init = {
  products: ['sgl'],
  legacy: INIT_LEGACY,
  sgl: INIT_SGL,
  sgwg: INIT_SGWG,
}
const validateStorage = (obj = init) => {
  // check if storage has same prop types as init object
  const valid = _.every(obj, (prop, name) => typeof prop === typeof init[name])
  return valid ? obj : init
}
const storedData = validateStorage(storage.get('userSearchToolbar'))
const initWithStorage = _.merge(init, storedData)
// save to storage and return saved state
const saveStorage = (state) => {
  storage.set('userSearchToolbar', state, 7)
  return state
}

export const toolbar = (state = initWithStorage, action) => {
  switch (action.type) {
    case TOGGLE_FIELD:
      return saveStorage({
        ...state,
        [action.product]: state[action.product].map((option) => {
          if (option.field === action.fieldName) {
            return { ...option, value: !option.value }
          }
          return option
        }),
      })

    case TOGGLE_PRODUCT:
      return saveStorage(
        fromJS(state)
          .update('products', (products) =>
            toggleListItem(products.toJS(), action.product),
          )
          .toJS(),
      )

    case EDIT_RANGE:
      return saveStorage(
        fromJS(state)
          .setIn(
            ['sgl', action.fieldIndex, 'value', action.rangeIndex],
            action.value,
          )
          .toJS(),
      )

    case CLEAR_RANGE:
      return saveStorage(
        fromJS(state)
          .setIn(['sgl', action.fieldIndex, 'value'], [0, 0])
          .toJS(),
      )

    case SET_TOOLBAR:
      return action.settings

    default:
      return state
  }
}
