import React from 'react'
import universal from 'react-universal-component'
import AsyncComponentLoading from 'components/AsyncComponentLoading'

const asyncComponent = () => import(/* webpackChunkName: "admin" */ './Admin')

const UniversalComponent = universal(asyncComponent, {
  resolve: () => require.resolveWeak('./Admin'),
  chunkName: 'admin',
  loading: AsyncComponentLoading,
})

export default (props) => <UniversalComponent {...props} />
