import { NAME } from './constants'

export const ADD_ASSETS = `${NAME}/ADD_ASSETS`
export const REMOVE_ASSET = `${NAME}/REMOVE_ASSET`
export const EDIT_FILE_TYPE = `${NAME}/EDIT_FILE_TYPE`
export const EDIT_VIEW_TYPE = `${NAME}/EDIT_VIEW_TYPE`
export const EDIT_ASSET_FILTER = `${NAME}/EDIT_ASSET_FILTER`

export const addAsset = (asset) => ({
  type: ADD_ASSETS,
  assets: [asset],
})

export const removeAsset = (path) => ({
  type: REMOVE_ASSET,
  path,
})

export const editFileType = (fileType) => ({
  type: EDIT_FILE_TYPE,
  fileType,
})

export const editViewType = () => ({
  type: EDIT_VIEW_TYPE,
})

export const editAssetFilter = (filter) => ({
  type: EDIT_ASSET_FILTER,
  filter,
})
