import ms from 'ms'
import _ from 'lodash'
import moment from 'moment'
import gameSettings from 'settings/games'
import shortid from 'shortid'
import storage from '@mfg/common/utils/storage'
import { customAlphabet } from 'nanoid'

export const prependZeros = (num, length) => {
  let final = num
  for (let i = 0; i < length - num.toString().length; i++) {
    final = '0' + final
  }
  return final
}

const formatWithZero = (num) => (num < 10 ? '0' + num : num)

export const formatTime = (ms, format) => {
  const s = Math.floor(ms / 1000)

  // NOTE: if days are enabled
  // const d = Math.floor(s / 60 / 60 / 24)
  // const h = Math.floor((s / 60 / 60) - (d * 24))
  // const m = Math.floor((s / 60) - (d * 24 + h) * 60)
  // const sec = Math.floor(s % 60)

  // NOTE: if hours are enabled
  // const h = Math.floor((s / 60 / 60))
  // const m = Math.floor((s / 60) - (h * 60))
  // const sec = Math.floor(s % 60)

  // NOTE: if seconds are enabled
  const m = Math.floor(s / 60)
  const sec = Math.floor(s % 60)

  return {
    // days: format ? formatWithZero(d) : d,
    // hours: format ? formatWithZero(h) : h,
    minutes: format ? formatWithZero(m) : m,
    seconds: format ? formatWithZero(sec) : sec,
  }
}

export const cloudTimeToLocal = (date, format = 'DD MMM YYYY HH:mm:ss') =>
  moment
    .utc(date)
    .local()
    .format(format)

export const localTimeToCloud = (date) =>
  moment(date)
    .utc()
    .format('YYYY-MM-DD HH:mm:ss')

export const cloudUrlToVersion = ({ hosts, cloudVer }) =>
  hosts[cloudVer].replace('https://', '').replace('.appspot.com/', '')

export const datastoreUrl = (cloud, key) =>
  `https://console.cloud.google.com/datastore/entities/query?project=${cloudUrlToVersion(
    cloud,
  )}&ns=&kind=GameEvent&filter=7%2F__key__%7CKEY%7CEQ%7C53%2FKey(Namespace(%27%27),%20GameEvent,%20%27${encodeURIComponent(
    key,
  )}%27)`

export const didCloudChange = (prev, next) => {
  if (!prev || !next) {
    return
  }
  let changedProps = ['cloudVer', 'prodId', 'appVer'].filter(
    (prop) => prev[prop] !== next[prop],
  )

  return changedProps.length !== 0
}

export const getRankFromXp = (prodId, xp) => {
  let rank = 0
  const xps = gameSettings[prodId].Ranks

  while (xp > xps[rank]) {
    rank += 1
  }
  return rank
}

export const getXpFromRank = (prodId, rank) => {
  const xps = gameSettings[prodId].Ranks

  return xps[rank]
}

export const stringToBool = (val) =>
  val === 'True' ? true : val === 'False' ? false : Boolean(val)

export const formatPlayTime = (seconds) => {
  const sec = Math.floor(seconds)
  const days = Math.floor(sec / 60 / 60 / 24)
  const hours = Math.floor((sec - days * 60 * 60 * 24) / 60 / 60)
  const min = Math.floor((sec - days * 60 * 60 * 24 - hours * 60 * 60) / 60)

  return (
    (days > 0 ? days + 'd ' : '') + (hours > 0 ? hours + 'h ' : '') + min + 'm'
  )
}

export const parseReactTags = (tags) => tags.map((text, id) => ({ text, id }))
export const parseReactSelect = (list) =>
  list.map((value) => ({ value, label: value }))

export const generateIdsToArray = (arr) =>
  arr.map((props) => ({ ...props, __adminUid: shortid.generate() }))

export const generateIdsToObjectArrays = (obj) =>
  applyMethodToObjectArrays(obj, generateIdsToArray)

export const removeIdsFromArray = (arr) =>
  arr.map((obj) => _.omit(obj, '__adminUid'))

export const removeIdsFromObjectArrays = (obj) =>
  applyMethodToObjectArrays(obj, removeIdsFromArray)

const applyMethodToObjectArrays = (obj, method) =>
  _.reduce(
    obj,
    (res, val, key) => {
      if (_.isArray(val)) {
        return { ...res, [key]: method(val) }
      }
      if (_.isPlainObject(val)) {
        return { ...res, ...applyMethodToObjectArrays(val, method) }
      }
      return { ...res, [key]: val }
    },
    {},
  )

const breakpoints = {
  mobile: 400,
  phablet: 550,
  tablet: 750,
  desktop: 1000,
  desktophd: 1367,
  fullhd: 1600,
}

// screen width detection
export const bp = (breakpoint, trueVal, falseVal) => {
  // if breakpoint is a number, check against window width
  if (typeof breakpoint === 'number') {
    return window.innerWidth >= breakpoint
  }
  // go through breakpoints and check
  // if the asked breakpoint passes the min-width test
  const bpPassed =
    Object.keys(breakpoints)
      .filter((bp) => window.innerWidth >= breakpoints[bp])
      .indexOf(breakpoint) > -1

  if (trueVal) {
    // if False value is undefined and True value is string
    // we expect that this method is used for a React component className
    // and so we don't want to return 'undefined' in case screen width doesn't pass
    if (falseVal === undefined && typeof trueVal === 'string') {
      falseVal = ''
    }
    return bpPassed ? trueVal : falseVal
  }
  return bpPassed
}

// obj parameter must be either array or object
export const objContains = (obj, val, returnVal) => {
  let contains

  if (Array.isArray(obj)) {
    contains = obj.some((elem) => elem === val)
  } else {
    contains = Object.keys(obj).some((key) => obj[key] === val)
  }

  if (returnVal && contains) {
    return returnVal
  }
  return contains
}

export const isScrolledIntoView = (el) => {
  const elemTop = el.getBoundingClientRect().top
  const elemBottom = el.getBoundingClientRect().bottom

  return elemTop >= 0 && elemBottom <= window.innerHeight
}

export const toggleListItem = (list, item) => {
  let newList
  // if item in current list, take it out
  if (list.includes(item)) {
    newList = list.filter((listItem) => listItem !== item)
    // otherwise add to current list
  } else {
    newList = [...list, item]
  }
  return newList
}

export const stripProp = (arr, prop) => arr.map((obj) => obj[prop])

export const splitCamelCase = (str) => str.replace(/([a-z])([A-Z])/g, '$1 $2')

// create id without any nonalphanumeric
export const makeId = (string) =>
  string.toLowerCase().replace(/[^a-zA-Z\d:]/, '_')

export const editStorageObj = (name, edit, maxAge = 7) => {
  const prev = storage.get(name) || {}

  storage.set(name, _.merge(prev, edit))
}

// simple (but not perfect) correct email string detection
export const isValidEmail = (mail) =>
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
    mail,
  )

export const hasProps = (obj, props, noFalsy) => {
  let has = true
  for (let prop of props) {
    if ((noFalsy && !obj[prop]) || (!noFalsy && obj[prop] === undefined)) {
      has = false
    }
  }
  return has
}

export const checkArrayForProps = (array, props, noFalsy) => {
  let invalid = []
  for (let [index, obj] of array.entries()) {
    if (!hasProps(obj, props, noFalsy)) {
      invalid.push(index)
    }
  }
  return invalid
}

export const parseSelectOptions = (options) => {
  if (options[0] && hasProps(options[0], ['value', 'label'])) {
    return options
  }
  return options.map((opt) => ({ value: opt, label: opt }))
}

export const stripSelectOptions = (options) => options.map((opt) => opt.value)

export const simpleId = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyz', 10)

// choose random element from array
export const random = (list) => {
  const num = Math.floor(Math.random() * list.length)
  return list[num]
}

export const capitalize = (txt) =>
  txt
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(' ')

export const recursiveJSONParse = (str) => {
  let parsed
  try {
    parsed = JSON.parse(str)
  } catch (err) {
    parsed = str
  }
  if (_.isPlainObject(parsed)) {
    return _.reduce(
      parsed,
      (c, v, k) => {
        return { ...c, [k]: recursiveJSONParse(v) }
      },
      {},
    )
  }
  return parsed
}

const PRODUCT_NAMES = {
  DeadTrigger: 'Dead Trigger',
  DeadTrigger2: 'Dead Trigger 2',
  Legends: 'Shadowgun Legends',
  Wargames: 'Shadowgun War Games',
}

export const renameProduct = (str) => PRODUCT_NAMES[str] || str

export const wait = (time) =>
  new Promise((resolve) => setTimeout(resolve, ms(time)))

// create a root DOM element into which a React component will render
// WARNING: it's up to you not to use same "id" for two Portals
export const createPortalRoot = (id) => {
  let elem = document.getElementById(id)
  if (!elem) {
    elem = document.createElement('div')
    elem.setAttribute('id', id)
    document.body.appendChild(elem)
  }
  return elem
}

export const setStatePromise = (component) => (newState) =>
  new Promise((resolve) => component.setState(newState, () => resolve()))

// Add 1st, 2nd, 3rd, 4th, ... to numbers
export const nth = (n) => n + ([, 'st','nd','rd'][n % 100 >> 3^1 && n % 10] || 'th')

export const validUrl = (url) =>
  /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    url,
  )
