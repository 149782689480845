import React from 'react'
import universal from 'react-universal-component'
import AsyncComponentLoading from 'components/AsyncComponentLoading'

const asyncComponent = () =>
  import(/* webpackChunkName: "development" */ './Development')

const UniversalComponent = universal(asyncComponent, {
  resolve: () => require.resolveWeak('./Development'),
  chunkName: 'development',
  loading: AsyncComponentLoading,
})

export default (props) => <UniversalComponent {...props} />
