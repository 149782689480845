import { combineReducers } from 'redux'

import {
  CLEAR_PRODUCT_SETTINGS,
  SET_TEXTS,
} from '../actions'

const texts = (prodId) => (state = {}, action) => {
  if (action.type === CLEAR_PRODUCT_SETTINGS) {
    return {}
  }
  if (action.prodId !== prodId) {
    return state
  }
  return versions(action.appVer)(state, action)
}

const versions = (appVer) => (state = {}, action) => {
  if (action.appVer !== appVer) {
    return state
  }
  if (action.type === SET_TEXTS) {
    return {
      ...state,
      [appVer]: action.texts,
    }
  }
  return state
}

export default combineReducers({
  Legends: texts('Legends'),
})
