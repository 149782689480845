import React, { Component } from 'react'
import { css } from '@emotion/css'

import Icon from 'components/Icon'
import GcloudFunctions from '@mfg/cloud/functions/gcloud'

class NewVersion extends Component {
  constructor(props) {
    super(props)
    this.state = {
      version: null,
      isNewVersion: false,
    }
  }

  componentDidMount = () => {
    if (!__DEV__) {
      this.startVersionCheck()
    }
  }

  componentWillUnmount = () => this.stopVersionCheck()

  startVersionCheck = () => {
    this.compareVersions()
    this.versionCheck = window.setInterval(this.compareVersions(), 60000)
  }

  stopVersionCheck = () => window.clearInterval(this.versionCheck)

  compareVersions = async () => {
    const { version } = this.state
    try {
      const res = await GcloudFunctions.getDefaultVersion(
        'madfingergames-admin',
        'default',
      )
      const latest = res.data.id
      if (!version) {
        this.setState({
          version: latest,
        })
      } else if (latest !== version) {
        this.stopVersionCheck()
        this.setState({
          isNewVersion: true,
        })
      }
    } catch (err) {}
  }

  render() {
    if (!this.state.isNewVersion) {
      return null
    }
    return (
      <div
        className={`${styles.wrap} animated fadeIn`}
        onClick={() => window.location.reload()}
      >
        <Icon className={styles.icon} icon='exclamation-circle' />
        <span>{'New version available.'}</span>
      </div>
    )
  }
}

const styles = {
  wrap: css`
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%);
    z-index: 100000;
    font-size: 20px;
    padding: 7px 10px;
    background-color: rgb(250, 250, 250);
    border-radius: 6px;
    border: 1px solid silver;
    cursor: pointer;
    color: #0a0a0a;
    opacity: 0.9;
    display: block;
    &:hover {
      opacity: 1;
      border-color: grey;
    }
  `,
  icon: css`
    color: rgb(215, 0, 0);
    margin-right: 7px;
  `,
}

export default NewVersion
