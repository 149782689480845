import {
  CLEAR_SETTINGS_ADMIN,
  LIST_SETTINGS_VERSIONS,
  LIST_VERSION_TYPES,
  SET_VERSION_TYPE_DATA,
  SELECT_SETTINGS_OPTION,
} from './actions'

const initAdmin = {
  versions: [],
  types: [],
  data: [],
  selected: {
    version: null,
    type: null,
    revision: 0,
    revisionDiff: 0,
  },
}

export default (state = initAdmin, action) => {
  switch (action.type) {
    case CLEAR_SETTINGS_ADMIN:
      return initAdmin

    case LIST_SETTINGS_VERSIONS:
      return { ...state, versions: action.versions }

    case LIST_VERSION_TYPES:
      return { ...state, types: action.types }

    case SET_VERSION_TYPE_DATA:
      return { ...state, data: action.data }

    case SELECT_SETTINGS_OPTION: {
      let newState = state
      if (action.key === 'version') {
        newState = { ...initAdmin, versions: state.versions }
      }
      if (action.key === 'type') {
        newState = { ...state, data: [] }
      }
      return {
        ...newState,
        selected: { ...state.selected, [action.key]: action.val },
      }
    }

    default:
      return state
  }
}
