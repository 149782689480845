import { combineReducers } from 'redux'
import { fromJS } from 'immutable'

import * as types from './actions'

const initAdmin = fromJS({ Roles: [], Permissions: [], Flags: [] })

const initUser = fromJS({
  ...initAdmin.toJS(),
  userid: '',
  invalid: [],
})

const admin = (state = initAdmin, action) => {
  switch (action.type) {
    case types.ADMIN_GET_ATTRIBUTES:
      return state.merge(fromJS(action.attributes))

    case types.ADMIN_ADD_ATTRIBUTE:
      return state.update(action.group, (group) => group.push(action.name))

    case types.ADMIN_REMOVE_ATTRIBUTE:
      return state.update(action.group, (group) =>
        group.delete(group.findIndex((name) => name === action.name)),
      )

    default:
      return state
  }
}

const user = (state = initUser, action) => {
  switch (action.type) {
    case types.GET_ATTRIBUTES:
      return state.merge(fromJS(action.attributes))

    case types.ADD_ATTRIBUTE:
      return state.update(action.group, (group) => group.push(action.name))

    case types.REMOVE_ATTRIBUTE:
      return state.update(action.group, (group) =>
        group.delete(group.findIndex((name) => name === action.name)),
      )

    case types.CHECK_INVALID_ATTRIBUTES:
      return state.set('invalid', action.invalid)

    case types.SET_USERID:
      return state.set('userid', action.userid)

    case types.SET_ATTR_USER_DATA:
      return state.set('userData', action.userData)

    case types.REMOVE_ALL_ATTRIBUTES:
      return state.merge(initAdmin)

    default:
      return state
  }
}

export default combineReducers({
  user,
  admin,
})
