import { fromJS } from 'immutable'
import * as types from '../types'

const init = {
  devices: [],
  device: undefined,
  deviceInfo: {},
  fetchingInfo: false,
  minNumRecords: 0,
  visible: {
    Low: true,
    Medium: true,
    High: true,
    UltraHigh: true,
  },
}

export const performance = (state = init, action) => {
  switch (action.type) {
    case types.LEGENDS_FETCH_START:
      return fromJS(state)
        .set('fetchingInfo', true)
        .toJS()

    case types.LEGENDS_FETCH_STOP:
      return fromJS(state)
        .set('fetchingInfo', false)
        .toJS()

    case types.LEGENDS_FETCH_DEVICES:
      return fromJS(state)
        .set('devices', action.devices)
        .toJS()

    case types.LEGENDS_FETCH_DEVICE_INFO:
      return fromJS(state)
        .set('deviceInfo', action.info)
        .toJS()

    case types.LEGENDS_SELECT_DEVICE:
      return fromJS(state)
        .set('device', action.name)
        .set('deviceInfo', {})
        .toJS()

    case types.LEGENDS_CHANGE_RECORDS:
      return fromJS(state)
        .set('minNumRecords', action.val)
        .toJS()

    case types.LEGENDS_TOGGLE_VISIBLE:
      return fromJS(state)
        .update('visible', (visible) =>
          visible.set(action.key, !visible.get(action.key)),
        )
        .toJS()

    default:
      return state
  }
}
