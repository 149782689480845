import React from 'react'
import PropTypes from 'prop-types'
import { Link as ReactLink } from 'react-router-dom'

const Link = ({ to = '', sameWindow, newWindow, children, ...props }) => {
  let link = to

  // if this is an external link
  if (
    (typeof to === 'string' && to.indexOf('http') === 0) ||
    props.deepLink ||
    newWindow
  ) {
    return (
      <a
        {...props}
        href={link}
        className={props.className}
        target={sameWindow ? '_self' : '_blank'}
        rel='noreferrer'>
        {children}
      </a>
    )
  }

  return (
    <ReactLink to={link} {...props}>
      {children}
    </ReactLink>
  )
}

Link.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.any,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  deepLink: PropTypes.bool,
  sameWindow: PropTypes.bool,
  newWindow: PropTypes.bool,
}

export default Link
