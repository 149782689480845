import React from 'react'
import { css, cx } from '@emotion/css'

export default () => (
  <div className={cx(wrap, 'content box')}>
    <h1 className='subtitle'>You have been logged out.</h1>
  </div>
)

const wrap = css`
  text-align: center;
  margin: 1rem;
`
