import { NAME } from './constants'

export const UPDATE_USER_DATA = `${NAME}/UPDATE_USER_DATA`
export const START_USER_FETCH = `${NAME}/START_USER_FETCH`
export const SET_FB_ACCOUNTS = `${NAME}/SET_FB_ACCOUNTS`
export const SET_USER_DATA = `${NAME}/SET_USER_DATA`
export const CLEAR_USER_DATA = `${NAME}/CLEAR_USER_DATA`
export const LOAD_PRODUCT_DATA = `${NAME}/LOAD_PRODUCT_DATA`
export const CHANGE_PRODUCT_DATA = `${NAME}/CHANGE_PRODUCT_DATA`
export const FETCH_PRODUCT_DATA = `${NAME}/FETCH_PRODUCT_DATA`
export const SAVE_CHANGES = `${NAME}/SAVE_CHANGES`
export const SET_USER_REPORTS = `${NAME}/SET_USER_REPORTS`
export const SET_ON_SAVE_FUNCTION = 'SET_ON_SAVE_FUNCTION'

export const setUserData = (user) => ({
  type: SET_USER_DATA,
  user,
})

export const clearUserData = () => ({
  type: CLEAR_USER_DATA,
})

export const updateUserData = (field, value, noConfirm) => {
  return {
    type: UPDATE_USER_DATA,
    field,
    value,
    noConfirm,
  }
}

export const changeProductData = (field, value, shouldSave) => ({
  type: CHANGE_PRODUCT_DATA,
  prodId: 'Unkilled',
  field,
  value,
  shouldSave,
})

export const setOnSave = (func) => {
  return {
    type: SET_ON_SAVE_FUNCTION,
    func,
  }
}
