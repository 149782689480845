import React from 'react'
import Loading from './Loading'

class AsyncComponentLoading extends React.Component {
  state = {
    loading: false,
  }

  componentDidMount = () => {
    this.timeout = window.setTimeout(() => this.setState({ loading: true }), 200)
  }

  componentWillUnmount = () => {
    window.clearTimeout(this.timeout)
  }

  render() {
    const { loading } = this.state

    if (!loading) {
      return null
    }
    return <Loading />
  }
}

export default AsyncComponentLoading
