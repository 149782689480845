import _ from 'lodash'
import { fromJS } from 'immutable'

import {
  LOAD_USERS,
  ADD_USER,
  ENABLE_USER,
  EDIT_USER,
  ADD_PERMISSION,
  REMOVE_PERMISSION,
  DELETE_USER,
} from './actions'

const init = []

const users = (state = init, action) => {
  switch (action.type) {
    case LOAD_USERS:
      return action.users

    case ADD_USER:
      return _.sortBy([...state, action.user], 'username')

    case ENABLE_USER:
      return state.map((user) => {
        if (action.user.username === user.username) {
          return { ...user, ...action.user }
        }
        return user
      })

    case EDIT_USER:
      return fromJS(state)
        .setIn([action.index, action.key], fromJS(action.value))
        .toJS()

    case ADD_PERMISSION:
      return state.map((user) => {
        if (action.username === user.username) {
          return {
            ...user,
            permissions: [...user.permissions, action.permission],
          }
        }
        return user
      })

    case REMOVE_PERMISSION:
      return state.map((user) => {
        if (action.username === user.username) {
          return {
            ...user,
            permissions: user.permissions.filter(
              (perm) => perm !== action.permission,
            ),
          }
        }
        return user
      })

    case DELETE_USER:
      return fromJS(state)
        .delete(action.index)
        .toJS()

    default:
      return state
  }
}

export default users
