import { NAME } from './constants'

export const SELECT_SETTINGS_OPTION = `${NAME}/SELECT_SETTINGS_OPTION`
export const CLEAR_SETTINGS_ADMIN = `${NAME}/CLEAR_SETTINGS_ADMIN`
export const SET_VERSION_TYPE_DATA = `${NAME}/SET_VERSION_TYPE_DATA`
export const LIST_SETTINGS_VERSIONS = `${NAME}/LIST_SETTINGS_VERSIONS`
export const LIST_VERSION_TYPES = `${NAME}/LIST_VERSION_TYPES`

export const clearSettingsAdmin = () => ({
  type: CLEAR_SETTINGS_ADMIN,
})

export const selectSettingsOption = (key, val) => ({
  type: SELECT_SETTINGS_OPTION,
  key,
  val,
})
