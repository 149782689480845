import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/css'

const Error = ({ error, info }) => (
  <div>
    <div className={`${styles.container} container`}>
      <div className='title'>🤕 Unexpected error!</div>
      <div className='subtitle'>We are sorry, this was quite unexpected.</div>

      <div className='content'>
        Please contact your favourite web developer about the issue.
      </div>

      <details className={styles.stack}>
        <div style={{ whiteSpace: 'pre-wrap' }}>
          {error && (error.message || error.toString())}
          <br />
          {info && info.componentStack}

          {error && error.stack}
        </div>
      </details>
    </div>
  </div>
)

Error.propTypes = {
  error: PropTypes.string,
  info: PropTypes.object,
}

const styles = {
  container: css`
    padding: 20px 10px;
    flex: 1;
  `,
  stack: css`
    padding: 20px;
    border: 1px solid silver;
    border-radius: 3px;
  `,
}

export default Error
