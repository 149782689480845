import { NAME } from './constants'

// TODO: don't fetch permissions on client,
// check them against server every time they're needed through session cookie

export const LOGIN_SUCCESS = `${NAME}/LOGIN_SUCCESS`
export const LOGIN_FAIL = `${NAME}/LOGIN_FAIL`
export const LOG_OUT = `${NAME}/LOG_OUT`
export const SET_ADMIN_USER_INFO = `${NAME}/SET_ADMIN_USER_INFO`

export const usernameSafe = (username) =>
  /@madfingergames.com$/.test(username)
    ? username
    : `${username}@decagames.com`

export const loginSuccess = (user) => ({
  type: LOGIN_SUCCESS,
  user,
})

export const loginFail = () => ({
  type: LOGIN_FAIL,
})

export const logOut = () => ({
  type: LOG_OUT,
})
