import { combineReducers } from 'redux'
import { fromJS } from 'immutable'
import _ from 'lodash'

import {
  START_SETTINGS_FETCH,
  STOP_SETTINGS_FETCH,
  LOAD_PRODUCT_VERSIONED,
  LOAD_TEXTS,
  LOAD_REGIONS,
  CLEAR_PRODUCT_SETTINGS,
  SET_PRODUCT_SETTINGS,
} from '../actions'

// DEPRECATED
// * ***** OLD SETTINGS REDUCER
const versionedInit = {
  fetching: false,
  all: {},
  texts: {
    Unkilled: {},
  },
  regions: {
    Unkilled: {},
  },
}

const versioned = (state = versionedInit, action) => {
  switch (action.type) {
    case START_SETTINGS_FETCH:
      return fromJS(state).set('fetching', true).toJS()

    case STOP_SETTINGS_FETCH:
      return fromJS(state).set('fetching', false).toJS()

    case LOAD_PRODUCT_VERSIONED:
      return fromJS(state)
        .set('fetching', false)
        .setIn(['all'], action.data)
        .toJS()

    case LOAD_TEXTS:
      return fromJS(state)
        .set('fetching', false)
        .setIn(['texts', action.prodId, action.appVer], action.texts)
        .toJS()

    case LOAD_REGIONS:
      return fromJS(state)
        .set('fetching', false)
        .setIn(['regions', action.prodId, action.appVer], action.regions)
        .toJS()

    default:
      return state
  }
}

// * ****** NEW SETTINGS REDUCER

const settings =
  (prodId) =>
  (state = {}, action) => {
    if (action.type === CLEAR_PRODUCT_SETTINGS) {
      return {}
    }
    if (action.prodId !== prodId) {
      return state
    }
    return versions(action.appVer)(state, action)
  }

const versions =
  (appVer) =>
  (state = {}, action) => {
    if (action.appVer !== appVer) {
      return state
    }
    if (action.type === SET_PRODUCT_SETTINGS) {
      return {
        ...state,
        [appVer]: _.merge(state[appVer] || {}, action.settings),
      }
    }
    return state
  }

export default combineReducers({
  Legends: settings('Legends'),
  Monzo: settings('Monzo'),
  DeadTrigger: settings('DeadTrigger'),
  DeadTrigger2: settings('DeadTrigger2'),
  Unkilled: settings('Unkilled'),
  // DEPRECATED
  versioned,
})
