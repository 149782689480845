import React from 'react'
import universal from 'react-universal-component'
import AsyncComponentLoading from 'components/AsyncComponentLoading'

const asyncComponent = () =>
  import(/* webpackChunkName: "mfgcloud" */ './Mfgcloud')

const UniversalComponent = universal(asyncComponent, {
  resolve: () => require.resolveWeak('./Mfgcloud'),
  chunkName: 'mfgcloud',
  loading: AsyncComponentLoading,
})

export default (props) => <UniversalComponent {...props} />
