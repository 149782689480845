import React from 'react'
// import cn from 'classnames'
import { css } from '@emotion/css'

// import Loading from 'components/Loading'
import Squirrel from 'components/Squirrel'

import { random } from 'utils/misc'

const QUOTES = [
  'Would you prefer chicken, steak, or tofu?',
  'Would you like fries with that?',
  'Pay no attention to the man behind the curtain.',
  'Checking the gravitational constant in your location.',
  'FUN FACT: the server is powered by a lemon and two electrodes.',
  "We're testing your patience.",
  '...as if you had any other choice.',
  '...the bits are flowing slowly today.',
  'Re-calibrating the internet...',
  'Please count to 10...',
  'Deterministically simulating the future...',
  'So... do you come here often?',
]

class Initializing extends React.Component {
  state = {
    text: random(QUOTES),
  }

  // componentDidMount = () => {
  //   this.interval = window.setInterval(() => {
  //     let text = random(QUOTES)
  //     while (text === this.state.text) {
  //       text = random(QUOTES)
  //     }
  //     this.setState({text})
  //   }, 5000)
  // }

  componentWillUnmount = () => {
    window.clearTimeout(this.interval)
  }

  render() {
    // const {text} = this.state
    return (
      <div className={`app ${styles.wrap}`}>
        <h2 className={styles.title}>Loading app...</h2>
        <Squirrel />
        {/* <Loading className='is-large' /> */}
        {/* <div className={cn(styles.text, 'animated fadeIn')} key={text}>
          {text}
        </div> */}
      </div>
    )
  }
}

const styles = {
  wrap: css`
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  `,
  text: css`
    margin-top: 1rem;
    display: flex;
    align-items: center;
  `,
  title: css`
    font-size: 1.5rem;
  `,
}

export default Initializing
