import store from 'redux/store'
import user from 'core/user'

// check if logged user has required permission and return true/false
export const hasAuth = (permission, allowDeveloper = true) => {
  const { permissions } = user.getInfo(store.getState())

  // check for developer permission "loophole"
  if (allowDeveloper && permissions.includes('developer')) {
    return true
  }
  return permissions.includes(permission)
}

export default hasAuth
