export const gcs = 'https://storage.googleapis.com/'

// export const cdn = 'https://e4r8s2u5.map2.ssl.hwcdn.net/'
// export const cdn = 'https://storage.googleapis.com/mfg-home/'
export const cdn = 'https://mfg-home.b-cdn.net/'


export const CDN_ROOT = {
//  'mfg-home': 'https://e4r8s2u5.map2.ssl.hwcdn.net/',
// 'mfg-home': 'https://storage.googleapis.com/mfg-home/',
  'mfg-home': 'https://mfg-home.b-cdn.net/',
  'mfg-crosspromo': 'https://z4c4f9a7.map2.ssl.hwcdn.net/',
}

export const CLOUD_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'
export const USER_DATE_TIME_FORMAT = 'DD MMM YYYY HH:mm:ss'
export const USER_DATE_FORMAT = 'DD MMM YYYY'

export const extensions = {
  'image/jpeg': 'jpg',
  'image/png': 'png',
}
