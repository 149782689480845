import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Markdown from 'react-markdown'
import hash from 'object-hash'

import { editStorageObj } from 'utils/misc'
import storage from '@mfg/common/utils/storage'

class Message extends React.Component {
  constructor(props) {
    super(props)
    // message id is hash based on message text
    this.id = props.id || hash.MD5(props.message).slice(0, 10)
    // load isHidden state from storage
    const hiddenMessages = storage.get('hiddenMessages') || {}
    const isHidden = hiddenMessages && hiddenMessages[this.id]
    this.state = { isHidden }
    // prolong storage isHidden state if component was supposed to be rendered.
    // this means that user is using the relevant interface and probably doesn't
    // need to see the message again
    if (isHidden) {
      editStorageObj('hiddenMessages', { [this.id]: true })
    }
  }

  hide = () => {
    // save isHidden to storage
    editStorageObj('hiddenMessages', { [this.id]: true })
    this.setState({ isHidden: true })
  }

  render() {
    const { message, header, hasClose, className } = this.props
    const { isHidden } = this.state

    if (isHidden || !message) {
      return null
    }
    return (
      <div className={cn('message', className)}>
        <div className='message-header'>
          {header !== false && <p>{header}</p>}
          {hasClose && (
            <button
              data-testid='close-btn'
              onClick={this.hide}
              className='delete'
              aria-label='delete'
            />
          )}
        </div>
        <div className='message-body'>
          <Markdown source={message} />
        </div>
      </div>
    )
  }
}

Message.defaultProps = {
  header: 'Info',
}

Message.propTypes = {
  message: PropTypes.string,
  id: PropTypes.string,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  hasClose: PropTypes.bool,
  className: PropTypes.string,
}

export default Message
