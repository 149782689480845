import _ from 'lodash'
import storage from '@mfg/common/utils/storage'

import { getInitialCloudSettings } from '../utils'

import {
  SET_CLOUD_PROP,
  SET_APP_VERS,
  SET_PERMISSIONS,
  SET_CLOUD_CREDENTIALS,
} from '../actions'

import settings from './settings'
import texts from './texts'

const initCloudSettings = getInitialCloudSettings()
const init = {
  passwords: {},
  hosts: {},
  appVers: {},
  allPermissions: [],
  ...initCloudSettings,
}
const STORED_PARAMS = ['cloudVer', 'prodId', 'appVer']

export default (state = init, action) => {
  switch (action.type) {
    case SET_CLOUD_PROP: {
      const newState = {
        ...state,
        [action.propName]: action.propValue,
      }
      // store in settings in local storage
      // TODO: move side effect out of reducer
      storage.set('cloud', _.pick(newState, STORED_PARAMS))
      return newState
    }

    case SET_APP_VERS:
      return {
        ...state,
        appVers: action.appVers,
      }

    case SET_PERMISSIONS:
      return {
        ...state,
        allPermissions: action.permissions,
      }

    case SET_CLOUD_CREDENTIALS: {
      const { hosts, passwords, services, products } = action
      return {
        ...state,
        hosts: { ...state.hosts, ...hosts },
        passwords: { ...state.passwords, ...passwords },
        services,
        products,
      }
    }

    default:
      return {
        ...state,
        settings: settings(state.settings, action),
        texts: texts(state.texts, action),
      }
  }
}
