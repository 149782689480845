import { combineReducers } from 'redux'

import { walkOfFame } from './walk-of-fame'
import { badWords } from './bad-words'
import { performance } from './performance'
import { guilds } from './guilds'
import { general } from './general'

export default combineReducers({
  walkOfFame,
  general,
  badWords,
  performance,
  guilds,
})
