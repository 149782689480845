import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { css } from '@emotion/css'

import CloudSettings from './CloudSettings'
import LoggedUser from './LoggedUser'

import app from 'core/app'

const Header = ({ options }) => {
  if (!showCloud(options) && !options.loggedUser) {
    return null
  }
  return (
    <nav className={styles}>
      {showCloud(options) && (
        <CloudSettings options={options} />
      )}
      {options.loggedUser && (
        <LoggedUser />
      )}
    </nav>
  )
}

Header.propTypes = {
  options: PropTypes.object.isRequired,
}

const showCloud = (options) => {
  return options.cloudVer || options.prodId || options.appVer
}

const styles = css`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 10px 10px 0;
  z-index: 3;
  position: absolute;
  height: 50px;
  right: 0;

  & + main {
    padding-top: 26px;
  }
`

const mapStateToProps = (state) => ({
  options: app.getHeader(state),
})

export default connect(mapStateToProps)(Header)
