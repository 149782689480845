import { fromJS } from 'immutable'

import * as types from '../types'

const init = {
  walkOfFame: [],
  originalWalkOfFame: [],
  editingWOF: false,
}

export function walkOfFame(state = init, action) {
  switch (action.type) {
    case types.WALK_OF_FAME_FETCH:
      return fromJS(state)
        .set('walkOfFame', fromJS(action.list))
        .set('originalWalkOfFame', fromJS(action.list))
        .set('fetching', state.fetching - 1)
        .toJS()

    case types.WALK_OF_FAME_SET:
      return fromJS(state)
        .set('walkOfFame', fromJS(action.list))
        .toJS()

    case types.WALK_OF_FAME_TOGGLE_EDIT:
      return fromJS(state)
        .set('editingWOF', !state.editingWOF)
        .toJS()

    case types.WALK_OF_FAME_ADD:
      return fromJS(state)
        .update('walkOfFame', (list) => list.push(action.name))
        .toJS()

    case types.WALK_OF_FAME_REMOVE:
      return fromJS(state)
        .update('walkOfFame', (list) => list.delete(action.index))
        .toJS()

    case types.WALK_OF_FAME_DRAG:
      return fromJS(state)
        .update('walkOfFame', (list) =>
          list.delete(action.curIndex).insert(action.newIndex, action.name),
        )
        .toJS()

    case types.WALK_OF_FAME_DISCARD:
      return fromJS(state)
        .set('walkOfFame', state.originalWalkOfFame)
        .toJS()

    default:
      return state
  }
}
