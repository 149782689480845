import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import hoistNonReactStatic from 'hoist-non-react-statics'

import Unauthorized from 'components/root/Unauthorized'

import { getDisplayName } from 'utils/hoc'
import { bindActionCreators } from 'redux'

import user from 'core/user'
import cloud from 'core/cloud'

// Allows user to view component only if they have required permissions
const withAuth = (permission, allowRequest = true) => (WrappedComponent) => {
  const EnhancedComponent = (props) => {
    const {
      loggedUser: { username, permissions },
      allPermissions,
    } = props
    // skip permission check for developers
    if (!permission || permissions.includes('developer')) {
      return <WrappedComponent {...props} />
    }
    // warn about obsolete permissions used in code
    if (!allPermissions.includes(permission)) {
      console.warn(`withAuth HOC doesn't support permission ${permission}.`)
    }
    // don't allow access without permission
    if (!permissions.includes(permission)) {
      // don't show anything if permission requests not allowed
      if (!allowRequest) {
        return null
      }
      return <Unauthorized username={username} permission={permission} />
    }
    return <WrappedComponent {...props} />
  }
  EnhancedComponent.displayName = `WithAuth(${getDisplayName(
    WrappedComponent,
  )})`
  EnhancedComponent.propTypes = {
    loggedUser: PropTypes.object.isRequired,
    allPermissions: PropTypes.array.isRequired,
  }
  return hoistNonReactStatic(
    connect(stateToProps, actionsToProps)(EnhancedComponent),
    WrappedComponent,
  )
}

const stateToProps = (state) => ({
  loggedUser: user.getInfo(state),
  allPermissions: cloud.getAllPermissions(state),
})

const actionsToProps = (dispatch) =>
  bindActionCreators({ logOut: user.logOut }, dispatch)

export default withAuth
