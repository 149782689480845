import { fromJS } from 'immutable'

import {
  LOAD_NOTES,
  SAVE_NOTES,
  ADD_NOTE,
  DELETE_NOTE,
  START_EDIT_NOTE,
  UPDATE_NOTE,
  CLEAR_NOTES,
} from './actions'

const INIT = {
  notes: [],
  notesChanged: false,
  editedNote: -1,
}

export default (state = INIT, action) => {
  switch (action.type) {
    case LOAD_NOTES:
      return fromJS(state)
        .set('notes', action.notes)
        .toJS()

    case SAVE_NOTES:
      return fromJS(state)
        .set('editedNote', -1)
        .toJS()

    case ADD_NOTE:
      return fromJS(state)
        .update('notes', (notes) => notes.push(action.note))
        .set('editedNote', state.notes.length)
        .toJS()

    case DELETE_NOTE:
      return {
        ...state,
        notes: state.notes.filter((n, i) => i !== action.index),
        editedNote: -1,
      }

    case START_EDIT_NOTE:
      return fromJS(state)
        .set('editedNote', action.index)
        .toJS()

    case UPDATE_NOTE:
      return fromJS(state)
        .setIn(['notes', action.index, 'text'], action.text)
        .toJS()

    case CLEAR_NOTES:
      return INIT

    default:
      return state
  }
}
