const reduxErrorMiddleware = () => (next) => async (action) => {
  try {
    return await Promise.resolve(next(action))
  } catch (err) {
    console.error(err)
    return next({
      type: 'GENERAL_ERROR',
      err,
    })
  }
}

export default reduxErrorMiddleware
