export default [
  {
    href: '/support/user',
    title: 'Customer Care',
    icon: 'users',
    permission: 'support_users',
    description:
      "Manage customers' accounts, their purchases and help solve their issues faster.",
  },
  {
    href: '/marketing/vouchers',
    title: 'Vouchers',
    icon: 'ticket-alt',
    permission: 'vouchers',
    description:
      'Create new vouchers, get voucher codes, make our users happy.',
  },
  {
    href: '/mfgcloud',
    title: 'Cloud',
    icon: 'cloud',
    permission: 'mfgcloud',
    description:
      'Interact with Madfinger Cloud, old and new, these tools are mainly aimed at the Cloud guys®.',
  },
  {
    href: '/web/cms',
    title: 'Web CMS',
    icon: 'globe',
    permission: 'web_home',
    description: 'Edit website content and its localizations.',
  },
]
