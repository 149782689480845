import React from 'react'
import universal from 'react-universal-component'
import AsyncComponentLoading from 'components/AsyncComponentLoading'

const asyncComponent = () =>
  import(/* webpackChunkName: "marketing" */ './Marketing')

const UniversalComponent = universal(asyncComponent, {
  resolve: () => require.resolveWeak('./Marketing'),
  chunkName: 'marketing',
  loading: AsyncComponentLoading,
})

export default (props) => <UniversalComponent {...props} />
