import { fromJS } from 'immutable'

import * as types from './types'

const initial = {
  list: [],
  texts: {
    TitleIds: {},
    DescriptionIds: {},
  },
  fetching: false,
  refreshing: false,
  eventType: 'Tournament',
  worldVersion: undefined,
  webgl_url: undefined,
}

export default (state = initial, action) => {
  switch (action.type) {
    case types.START_EVENTS_FETCH:
      return {
        ...state,
        fetching: true,
      }

    case types.START_EVENTS_REFRESH:
      return {
        ...state,
        refreshing: true,
      }

    case types.CLEAR_GAME_EVENTS:
      return {
        ...state,
        list: [],
      }

    case types.FETCH_GAME_EVENTS:
      return fromJS(state)
        .set('fetching', false)
        .set('refreshing', false)
        .update('list', (list) => {
          // Keep new events as it's undecided which cloud they will be uploaded to.
          let newEvents = list.filter(
            (event) =>
              event.get('isNew') &&
              // In case we are only refreshing the list, make sure that we're not
              // adding the same event for the second time, two conditions should be enough.
              // It's not bulletproof, so you still might lose a created event if it already
              // exists in the target list with the same Name and "Start Date"
              !action.tours.find((fetchedEvent) => {
                if (action.prodId === 'DeadTrigger2') {
                  return (
                    event.get('ArenaId') === fetchedEvent.ArenaId &&
                    event.get('startAt') === fetchedEvent.startAt
                  )
                }
                return (
                  event.get('Name') === fetchedEvent.Name &&
                  event.getIn(['Transitions', 'Active', 0, 'Date']) ===
                    fetchedEvent.Transitions.Active[0].Date
                )
              }),
          )

          return fromJS([...action.tours, ...newEvents.toJS()])
        })
        .toJS()

    case types.EDIT_TOURNAMENT_FIELD:
      return fromJS(state)
        .mergeIn(['list', action.index], { isEdited: action.markAsEdited })
        .setIn(
          [
            'list',
            action.index,
            ...(typeof action.field === 'object'
              ? action.field
              : [action.field]),
          ],
          fromJS(action.value),
        )
        .toJS()

    case types.REWARD_ITEM_REMOVE:
      return fromJS(state)
        .mergeIn(['list', action.tourIndex], { isEdited: true })
        .updateIn(['list', action.tourIndex, ...action.path], (rewardItems) =>
          rewardItems.delete(action.rewardItemIndex),
        )
        .toJS()

    case types.REWARD_ITEM_ADD:
      return fromJS(state)
        .mergeIn(['list', action.tourIndex], { isEdited: true })
        .updateIn(['list', action.tourIndex, ...action.path], (rewardItems) =>
          rewardItems.push(fromJS(action.rewardItem)),
        )
        .toJS()

    case types.REWARD_ITEM_EDIT:
      return fromJS(state)
        .mergeIn(['list', action.tourIndex], { isEdited: true })
        .setIn(['list', action.tourIndex, ...action.path], fromJS(action.value))
        .toJS()

    case types.REWARD_ITEM_SET:
      return fromJS(state)
        .mergeIn(['list', action.tourIndex], { isEdited: true })
        .setIn(
          ['list', action.tourIndex, ...action.path],
          fromJS(action.reward),
        )
        .toJS()

    case types.REWARD_ADD:
      return fromJS(state)
        .mergeIn(['list', action.tourIndex], { isEdited: true })
        .updateIn(['list', action.tourIndex, ...action.path], (rewards) =>
          rewards.push(fromJS(action.reward)),
        )
        .toJS()

    case types.REWARD_REMOVE:
      return fromJS(state)
        .mergeIn(['list', action.tourIndex], { isEdited: true })
        .updateIn(['list', action.tourIndex, ...action.path], (rewards) =>
          rewards.filter((rwd, i) => i !== action.rewardIndex),
        )
        .toJS()

    case types.REWARD_EDIT_LIMIT:
      return fromJS(state)
        .mergeIn(['list', action.tourIndex], { isEdited: true })
        .setIn(['list', action.tourIndex, ...action.path], action.value)
        .toJS()

    case types.DELETE_TOURNAMENT_FIELD:
      return fromJS(state)
        .mergeIn(['list', action.index], { isEdited: true })
        .deleteIn([
          'list',
          action.index,
          ...(typeof action.field === 'object' ? action.field : [action.field]),
        ])
        .toJS()

    case types.RESTORE_TOURNAMENT:
      return fromJS(state)
        .setIn(['list', action.index], fromJS(action.backup))
        .toJS()

    case types.ARCHIVE_TOURNAMENT:
      return fromJS(state)
        .deleteIn(['list', action.index])
        .toJS()

    case types.CREATE_TOURNAMENT:
      return fromJS(state)
        .update('list', (list) => list.insert(0, fromJS(action.tournament)))
        .toJS()

    case types.WORLD_VERSION_SET:
      return { ...state, worldVersion: action.version }

    case types.SET_WEBGL_URL:
      return { ...state, webgl_url: action.url }

    case types.CHANGE_EVENT_TYPE:
      return { ...state, eventType: action.eventType }

    default:
      return state
  }
}
