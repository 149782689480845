import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import Markdown from 'react-markdown'

const ABOUT_TEXT = `This is the ultimate administration tool for all things **Madfinger**!

*Honestly... This popup needs some improvements...*

If you want to know more about which tools have been used to build this site:

We're using React libraries such as react-router, react-hot-loader and react-redux. Another heavily used libraries include lodash, Less, Babel, Immutable, ESLint and Font-Awesome. Don't know what they are? *Google them up!*`

const AboutAdmin = ({ hide }) => (
  <div className='modal-card'>
    <header className='modal-card-head'>
      <h2 className='modal-card-title'>About</h2>
    </header>

    <section className='modal-card-body'>
      <div className='content'>
        <Markdown source={ABOUT_TEXT} />
      </div>
    </section>

    <footer className='modal-card-foot'>
      <Button onClick={hide} name='Ok' className='is-primary' />
    </footer>
  </div>
)

AboutAdmin.propTypes = {
  hide: PropTypes.func.isRequired,
}

export default AboutAdmin
