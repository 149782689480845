import React from 'react'
import { connect } from 'react-redux'

import Modal from '../Modal'
import PopupContent from './PopupContent'

import store from 'redux/store'

import app from 'core/app'

const Popup = (props) => (
  <Modal id='popup' onHide={cancelPopup}>
    <PopupContent {...props} cancel={cancelPopup} />
  </Modal>
)

// any code after "await Popup.confirm" doesn't get executed if Popup is canceled!
// NOTE: we don't resolve the awaiting Promise on cancel
// it's not supposed to cause memory leaks
// https://stackoverflow.com/questions/20068467/do-never-resolved-promises-cause-memory-leak
const cancelPopup = () => {
  store.dispatch(app.setPopup({}))
  Modal.hide('popup')
}

// simple alert message popup with an Ok button
Popup.alert = (text, title) => {
  return new Promise((resolve) => {
    store.dispatch(app.setPopup({
      text,
      title,
      onSubmit: resolve,
      type: 'alert',
    }))
    Modal.show('popup')
  })
}

// confirm popup with Ok/Cancel buttons
Popup.confirm = (text, title) => {
  return new Promise((resolve) => {
    store.dispatch(
      app.setPopup({
        text,
        title,
        onSubmit: resolve,
        type: 'confirm',
      }),
    )
    Modal.show('popup')
  })
}

// shows input field, returns entered value in a Promise
Popup.prompt = (text, title, placeholder) => {
  return new Promise((resolve) => {
    store.dispatch(
      app.setPopup({
        text,
        title,
        placeholder,
        onSubmit: resolve,
        type: 'prompt',
      }),
    )
    Modal.show('popup')
  })
}

// shows yes/no buttons, returns answer in a Promise
Popup.bool = (text, title) => {
  return new Promise((resolve) => {
    store.dispatch(
      app.setPopup({
        text,
        title,
        onSubmit: resolve,
        type: 'bool',
      }),
    )
    Modal.show('popup')
  })
}

// shows selection Dropdown with supplied options, returns selected value in a Promise
Popup.select = (text, title, options) => {
  return new Promise((resolve, reject) => {
    store.dispatch(
      app.setPopup({
        text,
        title,
        options,
        onSubmit: resolve,
        onCancel: reject,
        type: 'select',
      }),
    )
    Modal.show('popup')
  })
}

export default connect((state) => state.core.app.dom.popup)(Popup)
