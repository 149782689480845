import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { cdn } from 'settings/base'

const DEFAULT_SRC = `${cdn}shared/logos/mfg_logo_black_square.jpg`

const ProfilePic = ({ src = DEFAULT_SRC, ...rest }) => (
  <Img
    {...rest}
    src={src}
  />
)

ProfilePic.propTypes = {
  src: PropTypes.string,
}

const Img = styled('img')`
  width: 100%;
  height: 100%;
  max-width: 35px;
  max-height: 35px;
  border-radius: 50px;
`

export default ProfilePic
