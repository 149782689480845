import { NAME } from './constants'

export const SET_HEADER = `${NAME}/SET_HEADER`
export const START_FETCH = `${NAME}/START_FETCH`
export const STOP_FETCH = `${NAME}/STOP_FETCH`
export const APP_STOP_INIT = `${NAME}/APP_STOP_INIT`
export const SHOW_LOGGED_USER = `${NAME}/SHOW_LOGGED_USER`
export const HIDE_LOGGED_USER = `${NAME}/HIDE_LOGGED_USER`
export const EDIT_SETTINGS = 'EDIT_SETTINGS'
export const GENERAL_ERROR = 'GENERAL_ERROR'
export const TOGGLE_DOM_ELEMENT = 'TOGGLE_DOM_ELEMENT'
export const TOGGLE_MODAL = 'TOGGLE_MODAL'
export const SET_POPUP = 'SET_POPUP'

export const requestStart = (request) => ({
  type: START_FETCH,
  request,
})

export const requestStop = (request) => ({
  type: STOP_FETCH,
  request,
})

export const setHeader = (options) => {
  if (!options) {
    return { type: 'NO_OP' }
  }
  const headerOptions = options.reduce((final, option) => ({
    ...final,
    [option]: true,
  }), {})
  return {
    type: SET_HEADER,
    options: headerOptions,
  }
}

export const unsetHeader = (options) => {
  if (!options) {
    return { type: 'NO_OP' }
  }
  const headerOptions = options.reduce((final, option) => ({
    ...final,
    [option]: false,
  }), {})
  return {
    type: SET_HEADER,
    options: headerOptions,
  }
}

export const showLoggedUser = () => ({
  type: SHOW_LOGGED_USER,
})

export const hideLoggedUser = () => ({
  type: HIDE_LOGGED_USER,
})

export const editSettings = (section, settings) => ({
  type: EDIT_SETTINGS,
  section,
  settings,
})

export const generalError = (err) => {
  return {
    type: GENERAL_ERROR,
    err,
  }
}

export const toggleDomElement = (path) => {
  if (typeof path === 'string') {
    path = [path]
  }
  return {
    type: TOGGLE_DOM_ELEMENT,
    path,
  }
}

export const toggleModal = (modalId, isVisible) => ({
  type: TOGGLE_MODAL,
  modalId,
  isVisible,
})

export const setPopup = (options) => ({
  type: SET_POPUP,
  options,
})
