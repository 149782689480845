import * as types from './actions'

const init = {
  users: [],
  group: undefined,
  showBanned: true,
}

export default (state = init, action) => {
  switch (action.type) {
    case types.FETCH_TOP_REPORTED_USERS:
      return {
        ...state,
        users: action.users,
      }

    case types.CLEAR_TOP_REPORTED_USERS:
      return {
        ...state,
        users: [],
      }

    case types.CHANGE_REPORT_GROUP:
      return {
        ...state,
        group: action.group,
      }

    case types.TOGGLE_SHOW_BANNED:
      return {
        ...state,
        showBanned: !state.showBanned,
      }

    default:
      return state
  }
}
