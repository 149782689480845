import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import ClickOutHandler from 'components/ClickOutHandler'

import DropdownButton from './DropdownButton'
import DropdownItem from './DropdownItem'

import withToggle from 'utils/withToggle'
import { hasProps } from 'utils/misc'

// Single button Component handling
// Dropdown renders either custom children (usually set of <DropdownItem />'s)
// or an array of buttons with the following structure:
// [{
//   icon: 'eraser',
//   name: 'Delete',
//   onClick: this.delete
// }]
// simple strings
// {name, value} objects
// {icon, name, onClick} objects
const Dropdown = (props) => {
  const {
    guiState: { showPopup },
    children,
    disabled,
    options,
    className,
    hideOnClick,
    label,
    onChange,
    optionsPlaceholder,
  } = props
  let optionsSafe = parseOptions(options, onChange)

  return (
    <ClickOutHandler onClickOut={() => props.toggleGui('showPopup', false)}>
      <div className='control'>
        {label && <label className='label'>{label}</label>}
        <span className={cn('dropdown', className, { 'is-active': showPopup })}>
          <DropdownButton {...props} />

          {showPopup && (
            <div
              onClick={() => hideOnClick && props.toggleGui('showPopup', false)}
              className='dropdown-menu'
              role='menu'
            >
              <div className='dropdown-content'>
                {children ||
                  (optionsSafe.length ? (
                    optionsSafe.map((item) => (
                      <DropdownItem disabled={disabled} key={item.name} {...item} />
                    ))
                  ) : (
                    <div className='dropdown-item'>
                      <i>{optionsPlaceholder}</i>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </span>
      </div>
    </ClickOutHandler>
  )
}
const optionsProps = PropTypes.arrayOf(
  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    PropTypes.shape({
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      disabled: PropTypes.bool,
      onClick: PropTypes.func,
    }),
  ]),
)
Dropdown.propTypes = {
  guiState: PropTypes.object.isRequired,
  toggleGui: PropTypes.func.isRequired,
  children: PropTypes.node,
  options: optionsProps,
  hideOnClick: PropTypes.bool,
  className: PropTypes.string,
  optionsPlaceholder: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}
Dropdown.defaultProps = {
  name: '',
  optionsPlaceholder: 'No options.',
  children: null,
  options: [],
  hideOnClick: true,
  onChange: () => {
    console.warn('Dropdown onChange prop not defined!')
  },
}
// support specifying Dropdown options as
//   1) a simple array of strings, or
//   2) array of objects with {name, value} keys
//   3) array of objects with {name, icon?, onClick} keys
// ! need to provide onChange prop in this case,
//   otherwise will throw custom warning onClick
const parseOptions = (options, onChange) => {
  const opt = options[0]
  const type = typeof opt

  // 1)
  if (type === 'string' || type === 'number' || opt === null) {
    return options.map((option, index) => ({
      name: option,
      onClick: () => onChange(option, index),
    }))
  }
  // 2)
  if (opt && hasProps(opt, ['name', 'value'])) {
    return options.map((option, index) => ({
      name: option.name,
      onClick: () => onChange(option.value, index),
    }))
  }
  // 3)
  if (
    opt &&
    (hasProps(opt, ['name', 'onClick']) || hasProps(opt, ['name', 'href']))
  ) {
    return options
  }
  return []
}
const initState = {
  showPopup: false,
}

// ** ******* Public utils
Dropdown.getName = (value, options) =>
  options.find((type) => value === type.value).name

export default withToggle(Dropdown, initState)
