import _ from 'lodash'
import MfgcloudFunctions from '@mfg/cloud/functions/mfgcloud'
import PortalFunctions from '@mfg/cloud/functions/portal'
import { NewhopeAdmin } from 'cloud/newhope'

import cloud from 'core/cloud'
import app from 'core/app'

import {
  SET_APP_VERS,
  SET_PERMISSIONS,
  SET_CLOUD_CREDENTIALS,
  SET_TEXTS,
  SET_PRODUCT_SETTINGS,
  START_SETTINGS_FETCH,
  STOP_SETTINGS_FETCH,
  LOAD_TEXTS,
  LOAD_REGIONS,
  setCloudProp,
  setAppVer,
} from './actions'

export const setProdId = (prodId) => (dispatch, getState) => {
  const { appVers, cloudVer } = cloud.getCloud(getState())
  dispatch(setCloudProp('prodId', prodId))
  // switch to latest available app version for given product/cloud
  dispatch(setCloudProp('appVer', _.last(appVers[prodId][cloudVer])))
}

export const setCloudVer = (cloudVer) => (dispatch, getState) => {
  const { appVers, prodId } = cloud.getCloud(getState())
  dispatch(setCloudProp('cloudVer', cloudVer))
  // switch to latest available app version for given product/cloud
  dispatch(setCloudProp('appVer', _.last(appVers[prodId][cloudVer])))
}

export const fetchAppVers = () =>
  async function fetchLegendsVersions(dispatch, getState) {
    const res = await MfgcloudFunctions.fetchLegendsVersions()
    const { prodId, cloudVer } = cloud.getCloud(getState())
    const appVers = res.data
    dispatch({
      type: SET_APP_VERS,
      appVers,
    })
    const productAppVers = appVers[prodId][cloudVer]
    dispatch(setAppVer(_.last(productAppVers)))
  }

export const fetchPermissions = () => async (dispatch) => {
  const res = await PortalFunctions.fetchPermissions()
  dispatch({
    type: SET_PERMISSIONS,
    permissions: res.data,
  })
}

export const fetchCloudCredentials = () => async (dispatch) => {
  const res = await PortalFunctions.fetchCloudCredentials()
  dispatch({
    type: SET_CLOUD_CREDENTIALS,
    ...res.data,
  })
}

export const loadTexts =
  (reqSettings, options = {}) =>
  async (dispatch, getState) => {
    const opt = _.isPlainObject(reqSettings) ? reqSettings : options
    const cld = cloud.getCloud(getState())
    const appVer = opt.appVer || cld.appVer
    const prodId = opt.prodId || cld.prodId
    const cloudVer = (opt.cloudVer || cld.cloudVer).toLowerCase()

    try {
      const res = await MfgcloudFunctions.fetchLegendsTexts(
        cloudVer,
        appVer,
        'eng',
      )
      const texts = _.mapKeys(res.data.result.Value, (value, key) =>
        parseInt(key, 10),
      )
      dispatch({
        type: SET_TEXTS,
        prodId,
        appVer,
        texts,
      })
    } catch (err) {}
  }

export const loadProductSettings =
  (reqSettings, options = {}) =>
  async (dispatch, getState) => {
    // if first param is object, expect it to be options
    const opt = _.isPlainObject(reqSettings) ? reqSettings : options
    // if first param is object, settings are not defined (will fetch all settings available)
    const set = _.isPlainObject(reqSettings) ? undefined : reqSettings
    const setSafe = set && (_.isArray(set) ? set : [set])

    // get cloud settings from options param or cloud reducer
    const {
      core: { cloud: cld },
      mfgcloud: { settings },
    } = getState()
    const appVer = opt.appVer || cld.appVer
    const prodId = opt.prodId || cld.prodId
    const cloudVer = (opt.cloudVer || cld.cloudVer).toLowerCase()
    const { noCache } = opt

    // check if required settings are already fetched, don't fetch them again
    const fetched = _.get(settings, [prodId, appVer]) || {}
    const fetchSettings =
      setSafe &&
      setSafe.filter((setting) => noCache || (!noCache && !fetched[setting]))
    if (fetchSettings && fetchSettings.length === 0) {
      return
    }
    dispatch(app.requestStart({ id: 'product-settings' }))
    try {
      const res = await MfgcloudFunctions.fetchProductSettings(
        prodId,
        cloudVer,
        appVer,
        fetchSettings,
        noCache,
      )
      dispatch({
        type: SET_PRODUCT_SETTINGS,
        prodId,
        appVer,
        settings: res.data.result,
      })
    } catch (err) {
      console.error('ERROR (admin/src/core/cloud/effects.js):' + err)
    } finally {
      dispatch(app.requestStop({ id: 'product-settings' }))
    }
  }

// DEPRECATED
export const loadVersionedTexts =
  (prodId, appVer, lang = 'eng') =>
  async (dispatch) => {
    dispatch({ type: START_SETTINGS_FETCH })
    const texts = await NewhopeAdmin.getVersionedData(
      prodId,
      appVer,
      `Texts.${lang}`,
    )

    if (!texts) {
      return dispatch({ type: STOP_SETTINGS_FETCH })
    }
    dispatch({
      type: LOAD_TEXTS,
      prodId,
      appVer,
      texts,
    })
  }

// DEPRECATED
export const loadVersionedRegions = (prodId, appVer) => async (dispatch) => {
  dispatch({ type: START_SETTINGS_FETCH })
  const res = await NewhopeAdmin.getVersionedData(prodId, appVer, 'Map')

  const regions = JSON.parse(res)

  if (!regions) {
    return dispatch({ type: STOP_SETTINGS_FETCH })
  }
  dispatch({
    type: LOAD_REGIONS,
    prodId,
    appVer,
    regions: regions['map'].regions,
  })
}
