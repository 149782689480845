import _ from 'lodash'
import qs from 'query-string'

// TODO: shouldn't use actions from a different store
import { setToolbar } from 'sections/support/user/UserSearch/_store/actions'

import user from 'core/user'
import cloud from 'core/cloud'

import { APP_STOP_INIT } from './actions'

export const init = () => async (dispatch) => {
  // parse and remove query parameters
  dispatch(parseUrlQuery())
  // login user
  const authorized = await dispatch(user.loginUser())
  if (!authorized) {
    dispatch({ type: APP_STOP_INIT })
    return
  } else {
    Object.keys(authorized.envData).forEach((key) => {
      process.env[key] = authorized.envData[key]
    })
  }
  await Promise.all([
    // cloud credentials (hostnames, passwords)
    // TODO: don't send this data to client at all
    dispatch(cloud.fetchCloudCredentials()),
    // all available permissions
    // TODO: move to appropriate Components, shouldn't be needed on start up
    dispatch(cloud.fetchPermissions()),
    // available products and app versions
    dispatch(cloud.fetchAppVers()),
  ])
  dispatch({ type: APP_STOP_INIT })
}

export const parseUrlQuery = () => (dispatch, getState) => {
  const core = getState().core
  const { search, origin, pathname } = window.location
  const query = qs.parse(search)
  // go through changeable cloud props and edit those that
  // are currently different to those in url
  cloud.ALLOWED_CLOUD_PROPS.forEach((prop) => {
    const value = query[prop]
    if (value && value !== core.cloud[prop]) {
      dispatch(cloud.setCloudProp(prop, value))
    }
    // remove cloud prop from url query
    delete query[prop]
  })
  if (query.search) {
    try {
      const settings = JSON.parse(query.search)
      console.log('dispatch setting toolbar', settings)
      dispatch(setToolbar(settings))
    } catch (err) {
      console.log('Failed parsing url param "search".')
    }
    // remove search options from url query
    delete query.search
  }
  let newUrl = origin + pathname
  // add remaining query params if there are any left
  if (!_.isEmpty(query)) {
    newUrl += `?${qs.stringify(query)}`
  }
  window.history.replaceState({}, '', newUrl)
}
