export const NAME = 'games-leaderboards'

export const LEGENDS_ARENAS = [
  {
    arenaId: 'fame',
    name: 'Fame',
  },
  {
    arenaId: 'fame-weekly',
    name: 'Fame weekly',
  },
  {
    arenaId: 'fame-monthly',
    name: 'Fame monthly',
  },
  {
    arenaId: 'pvp-duel',
    name: 'Duels',
  },
  {
    arenaId: 'pvp-duel-weekly',
    name: 'Duels weekly',
  },
  {
    arenaId: 'pvp-duel-monthly',
    name: 'Duels monthly',
  },
  {
    arenaId: 'pvp-supremacy',
    name: 'Supremacy',
  },
  {
    arenaId: 'pvp-supremacy-weekly',
    name: 'Supremacy weekly',
  },
  {
    arenaId: 'pvp-supremacy-monthly',
    name: 'Supremacy monthly',
  },
  {
    arenaId: 'guilds',
    name: 'Guilds',
  },
  {
    arenaId: 'pvp-capturetheflag-weekly',
    name: 'CTF Weekly',
  },
  {
    arenaId: 'pvp-capturetheflag-monthly',
    name: 'CTF Monthly',
  },
]

