import * as types from './actions'

const init = {
  fetching: false,
  fetchingCodes: false,
  voucherSets: {},
  filters: {
    filterString: '',
  },
  sort: {
    property: 'ValidFrom',
    ascending: false,
  },
  newVoucher: {
    ID: 'Marketing.',
    Count: 1,
    Individual: true,
    Multiclaimable: false,
    rewards: [],
  },
  rewards: {},
}

const rewards = (state, action) => {
  switch (action.type) {
    case types.FETCH_REWARDS:
      return action.rewards

    case types.EDIT_REWARDS_ID:
      return {
        ...state.rewards,
        [action.rewardsId]: action.rewardsList,
      }

    default:
      return state
  }
}

const newVoucher = (state, action) => {
  switch (action.type) {
    case types.EDIT_NEW_VOUCHER:
      return {
        ...state,
        [action.prop]: action.value,
      }

    case types.RESET_NEW_VOUCHER:
      return init.newVoucher

    default:
      return state
  }
}

const voucherSets = (state, action) => {
  switch (action.type) {
    case types.SET_VOUCHERS:
      return action.vouchers

    case types.EDIT_VOUCHER:
      return {
        ...state,
        [action.name]: { ...state[action.name], ...action.data },
      }

    default:
      return state
  }
}

const filters = (state, action) => {
  switch (action.type) {
    case types.EDIT_VOUCHERS_FILTER:
      return {
        ...state,
        [action.filter]: action.value,
      }

    default:
      return state
  }
}

const sort = (state, action) => {
  switch (action.type) {
    case types.EDIT_VOUCHERS_SORT:
      return {
        property: action.property,
        ascending:
          state.property === action.property
            ? !state.ascending
            : state.ascending,
      }
    default:
      return state
  }
}

const vouchers = (state = init, action) => {
  switch (action.type) {
    case types.START_VOUCHER_FETCH:
      return { ...state, fetching: true }

    case types.STOP_VOUCHER_FETCH:
      return { ...state, fetching: false }

    case types.TOGGLE_FETCH_VOUCHER_CODES:
      return { ...state, fetchingCodes: !state.fetchingCodes }

    default:
      return {
        ...state,
        voucherSets: voucherSets(state.voucherSets, action),
        filters: filters(state.filters, action),
        sort: sort(state.sort, action),
        newVoucher: newVoucher(state.newVoucher, action),
        rewards: rewards(state.rewards, action),
      }
  }
}

export default vouchers
