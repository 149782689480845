import { fromJS } from 'immutable'

import {
  SET_TRANSACTIONS,
} from './actions'

const init = {
  list: [],
  unrecognized: [],
}

export default (state = init, action) => {
  switch (action.type) {
    case SET_TRANSACTIONS:
      return fromJS(state)
        .set('list', action.transactions)
        .set('unrecognized', action.unrecognized)
        .toJS()

    default:
      return state
  }
}
