import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'

import Icon from 'components/Icon'

import css from './Sidebar.less'

const getActiveClassName = (to) => {
  if (to !== '/' || (to === '/' && window.location.pathname === '/')) {
    return css.linkSelected
  }
}

const SidebarLink = ({
  to,
  icon,
  label,
  sidebarOpen,
  disabled,
  togglePin,
  isPinned,
}) => (
  <NavLink
    activeClassName={getActiveClassName(to)}
    to={to}
    className={cn(
      css.link,
      !icon && css.linkAutoIcon,
      !sidebarOpen && 'tooltip is-tooltip-right',
      { [css.linkDisabled]: disabled },
    )}
    data-tooltip={label}
  >
    {icon ? (
      <Icon size='lg' icon={icon} className={cn(css.icon)} />
    ) : (
      <span className={cn(css.icon, css.iconAuto)}>{label.charAt(0)}</span>
    )}
    <span className={cn(css.label)}>{sidebarOpen ? label : ''}</span>
    {sidebarOpen && isPinned !== undefined && !disabled && (
      <Icon
        className={cn(css.pinIcon, isPinned && css.pinned)}
        onClick={() => togglePin(to)}
        icon='thumbtack'
        size='lg'
      />
    )}
  </NavLink>
)

SidebarLink.defaultProps = {
  className: '',
}

SidebarLink.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  sidebarOpen: PropTypes.bool,
  disabled: PropTypes.bool,
  isPinned: PropTypes.bool,
  togglePin: PropTypes.func,
}

export default SidebarLink
