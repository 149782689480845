import * as actions from './actions'
import * as effects from './effects'
import * as constants from './constants'
import * as selectors from './selectors'
import reducer from './reducer'

export default {
  ...actions,
  ...effects,
  ...constants,
  ...selectors,
  reducer,
}
