import { fromJS } from 'immutable'
import _ from 'lodash'

import {
  START_USER_FETCH,
  SET_USER_DATA,
  CLEAR_USER_DATA,
  UPDATE_USER_DATA,
  SET_FB_ACCOUNTS,
} from '../actions'

const INIT = {
  changedFields: [],
  facebookAccounts: [],
  fetching: false,
  userDataLoaded: false,
  products: [],
}

export default (state = INIT, action) => {
  switch (action.type) {
    case START_USER_FETCH:
      return fromJS(state)
        .set('fetching', true)
        .toJS()

    case SET_USER_DATA:
      return fromJS(state)
        .merge(action.user)
        .set('originalUserData', action.user)
        .set('userDataLoaded', true)
        .set('fetching', false)
        .toJS()

    case CLEAR_USER_DATA:
      return INIT

    case UPDATE_USER_DATA: {
      const { field, value, noConfirm } = action

      return fromJS(state)
        .set(field, value)
        .update('changedFields', (fields = fromJS([])) =>
          fromJS(noConfirm ? fields : _.union(fields.toJS(), [field])),
        )
        .toJS()
    }

    case SET_FB_ACCOUNTS:
      return fromJS(state)
        .set('facebookAccounts', action.accounts)
        .toJS()

    default:
      return state
  }
}
