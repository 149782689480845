import { combineReducers } from 'redux'

import userData from './userData'
import gameData from './gameData'
import reports from './reports'
import onSaveFunction from './onSaveFunction'

export default combineReducers({
  userData,
  gameData,
  reports,
  onSaveFunction,
})
