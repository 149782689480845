import { combineReducers } from 'redux'

// support

import userSearch from 'sections/support/user/UserSearch/_store/reducer'
import userReducer from 'sections/support/user/UserDetail/_store/reducer'
import userNotes from 'sections/support/user/UserNotes/_store/reducer'
import transactions from 'sections/support/Transactions/_store/reducer'
import sglUser from 'sections/support/user/SglUserDetail/_store/reducer'

// mfgcloud
import settings from 'sections/mfgcloud/settings/_store/reducer'
import attributes from 'sections/mfgcloud/attributes/_store/reducer'

// web
import assets from 'sections/web/assets/_store/reducer'

// core
import app from 'core/app'
import cloud from 'core/cloud'
import user from 'core/user'

// games
import leaderboards from 'sections/games/Leaderboards/_store/reducer'
import messaging from 'sections/games/Messages/_store/reducer'
import reports from 'sections/games/Reports/_store/reducer'
import events from 'sections/games/Events/_store/reducer'
import legends from 'sections/games/Legends/_store/reducer'

// web
import cms from 'sections/web/cms/_store/reducer'

// admin
import users from 'sections/admin/_store/reducer'
import logs from 'sections/admin/Logs/_store/reducer'

// marketing
import vouchers from 'sections/marketing/Vouchers/_store/reducer'
import contacts from 'sections/marketing/Contacts/_store/reducer'

export const support = combineReducers({
  userSearch,
  transactions,
  user: userReducer,
  sglUser,
  userNotes,
})

const mfgcloud = combineReducers({
  attributes,
  settings,
})

const marketing = combineReducers({
  vouchers,
  contacts,
})

export const games = combineReducers({
  leaderboards,
  messaging,
  events,
  legends,
  reports,
})

const web = combineReducers({
  cms,
  assets,
})

export const admin = combineReducers({
  logs,
  users,
})

const core = combineReducers({
  app: app.reducer,
  cloud: cloud.reducer,
  user: user.reducer,
})

export default combineReducers({
  admin,
  games,
  marketing,
  mfgcloud,
  support,
  web,
  core,
})
