import {
  CLEAR_SEARCH,
  EDIT_SEARCH,
  ADD_USERS,
  UPDATE_USERS,
  START_SEARCH,
  SEARCH_DONE,
  TOGGLE_FIELD,
  TOGGLE_PRODUCT,
  EDIT_RANGE,
  CLEAR_RANGE,
  SET_TOOLBAR,
} from './types'

export const clearSearch = () => ({
  type: CLEAR_SEARCH,
})

export const editSearch = (path, val) => ({
  type: EDIT_SEARCH,
  path,
  val,
})

export const addUsers = (users) => ({
  type: ADD_USERS,
  users,
})

// merge original info about a user with newly found info
export const addInfo = (users) => ({
  type: UPDATE_USERS,
  // change fetch status so that user isn't fetched again
  users: users.map((user) => ({
    ...user,
    fetchDone: true,
  })),
})

export const startSearch = () => ({
  type: START_SEARCH,
})
export const endSearch = () => ({
  type: SEARCH_DONE,
})

export const toggleField = (product, fieldName) => ({
  type: TOGGLE_FIELD,
  fieldName,
  product,
})

export const toggleProduct = (product) => ({
  type: TOGGLE_PRODUCT,
  product,
})

export const editRange = (fieldIndex, rangeIndex, valueInput) => {
  // validate value
  let value = parseInt(valueInput, 10)
  if (Number.isNaN(value)) {
    value = undefined
  }
  return {
    type: EDIT_RANGE,
    fieldIndex,
    rangeIndex,
    value,
  }
}

export const clearRange = (fieldIndex) => {
  return {
    type: CLEAR_RANGE,
    fieldIndex,
  }
}

export const setToolbar = (settings) => {
  return {
    type: SET_TOOLBAR,
    settings,
  }
}
