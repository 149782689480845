import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { withRouter } from 'react-router-dom'
import { css } from '@emotion/css'

import Icon from 'components/Icon'
import Button from 'components/Button'
import Message from 'components/Message'

import PortalFunctions from '@mfg/cloud/functions/portal'
import sections from 'components/root/Sidebar/sections'

class Unauthorized extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sending: false,
      sent: false,
      error: false,
    }
  }

  requestAccess = async () => {
    let {
      username,
      permission,
      location: { pathname },
    } = this.props
    // add @decagames.com.com to the username, backend required it
    if (
      !username.includes('@gmail.com') &&
      !username.includes('@decagames.com')
    ) {
      username += '@decagames.com'
    }

    this.setState({ sending: true, error: false })
    try {
      await PortalFunctions.requestPermission(username, permission, pathname)
      this.setState({ sending: false, sent: true })
    } catch (err) {
      console.error(err)
      this.setState({ sending: false, error: true })
    }
  }

  render() {
    const { history, prevLocation, username, permission, location: { pathname } } = this.props
    const { sending, sent, error } = this.state
    const showRequest = username && permission
    const links = Object.keys(sections).reduce((accumulator, currentValue) => [...accumulator, ...sections[currentValue].links], [])    
    const currentLink = links.find(l => l.to === pathname)
    return (
      <div className={cn(styles.wrap, 'content box')}>
        <h1 className='title'>Access denied!</h1>
        {error && (
          <Message
            className='is-danger'
            message='There was en error while requesting access. Please contact @web department.'
          />
        )}
        <p>
          {"Unfortunately you don't have permissions to access this section."}
        </p>
        {currentLink && currentLink.useCases && (
          <>
            <h2 className='title'>Do you need it?</h2>
            <p>
              {"In this section you can:"}
            </p>
            <ul className={styles.list}>
              {currentLink.useCases.map((uc, i) => (
                <li key={i}>
                  {uc}
                </li>
              ))}
            </ul>
          </>
        )}        
        {document.location.href === 'https://portal.mfg.decagames.com/' && (
          <>
            <p>
              {'Or you can try it at '}
              <a href='https://madfingergames-admin.appspot.com/'>
                {`https://madfingergames-admin.appspot.com/${
                  location.pathname
                }`}
              </a>
              {'.'}
            </p>
          </>
        )}

        {showRequest && (
          <div className='buttons is-centered'>
            {!sent ? (
              <Button
                name='Request access'
                className={cn('is-info', sending && 'is-loading')}
                onClick={this.requestAccess}
              />
            ) : (
              <h5 className='has-text-success animated fadeIn'>
                <Icon icon='check-circle' className='has-margin-right-small' />
                Request sent
              </h5>
            )}
            {sent && prevLocation && (
              <Button
                className='is-toggle animated fadeIn'
                name='Go back'
                icon='angle-left'
                onClick={() => history.goBack()}
              />
            )}
          </div>
        )}
        {showRequest && !sent && (
          <p>
            {'or contact '}
            <a href='mailto:web@decagames.com'>{'@web'}</a>
            {' department directly to get it.'}
          </p>
        )}
      </div>
    )
  }
}

Unauthorized.propTypes = {
  username: PropTypes.string,
  permission: PropTypes.string,
  prevLocation: PropTypes.string,
  location: PropTypes.object,
  history: PropTypes.object,
}

const styles = {
  wrap: css`
    text-align: center;
    margin: 1rem;
  `,
  list: css `
    max-width: 500px;
    text-align: left;
    margin: 0 auto 2rem !important;
  `,
}

export default withRouter(Unauthorized)
