import React, { Component } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { compose } from 'redux'
import { connect } from 'react-redux'
import store from 'redux/store'

import app from 'core/app'

import { createPortalRoot } from 'utils/misc'

const modalRoot = createPortalRoot('modal')

export class Modal extends Component {
  shortcuts = (e) => {
    if (e.which === 27 && this.props.isVisible) {
      this.hideCurrent()
    }
  }

  componentDidMount = () => {
    document.addEventListener('keydown', this.shortcuts)
  }

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.shortcuts)
  }

  componentWillReceiveProps = (nextProps) => {
    const { isVisible, onShow } = this.props
    // detect showing Modal event
    if (!isVisible && nextProps.isVisible) {
      if (onShow) {
        onShow()
      }
    }
  }

  hideCurrent = () => {
    const { id, onHide } = this.props

    Modal.hide(id)
    if (onHide) {
      onHide()
    }
  }

  render() {
    const { isVisible, className, children } = this.props

    if (!isVisible) {
      return null
    }
    const modal = (
      <div
        ref={(c) => {
          this.modal = c
        }}
        className='modal is-active'
      >
        <div className='modal-background' onClick={this.hideCurrent} />
        <div className={cn('modal-content', className)}>{children}</div>
        <button
          className='modal-close is-large'
          aria-label='close'
          onClick={this.hideCurrent}
        />
      </div>
    )
    return createPortal(modal, modalRoot)
  }
}

Modal.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isVisible: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onHide: PropTypes.func,
  onShow: PropTypes.func,
}

Modal.toggle = (modalId) => {
  store.dispatch(app.toggleModal(modalId))
}
Modal.show = (modalId) => {
  store.dispatch(app.toggleModal(modalId, true))
}
Modal.hide = (modalId) => {
  store.dispatch(app.toggleModal(modalId, false))
}

export default compose(
  connect((state, props) => ({ isVisible: state.core.app.dom.modal[props.id] === true })),
)(Modal)
