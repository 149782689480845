import { NAME } from './constants'

export const EDIT_MESSAGE_FIELD = `${NAME}/EDIT_MESSAGE_FIELD`

export const editMessage = (product, field, value) => ({
  type: EDIT_MESSAGE_FIELD,
  product,
  field,
  value,
})
