import { fromJS } from 'immutable'

import * as types from '../actions'

const init = {
  sections: {},
  meta: {},
}

const original = (state = init, action) => {
  switch (action.type) {
    case types.CLEAR_CMS: {
      return init
    }
    case types.SET_CMS_META:
      return {
        ...state,
        meta: action.data,
      }

    case types.SET_CMS_SECTION:
      return fromJS(state)
        .setIn(['sections', action.sectionName], action.data)
        .toJS()

    case types.CMS_PUBLISH_SUCCESS:
      return {
        sections: action.sections,
        meta: action.meta,
      }

    default:
      return state
  }
}

export default original
