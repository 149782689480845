import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import reducers from './reducers'
import errors from './errors'

export default () =>
  createStore(
    reducers,
    composeWithDevTools(
      applyMiddleware(
        errors,
        thunk,
      ),
    ),
  )
