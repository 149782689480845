import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { css } from '@emotion/css'
import Markdown from 'react-markdown'

import Input from '../Input'
import Button from '../Button'
import Dropdown from '../Dropdown'

export const TYPES = ['alert', 'confirm', 'prompt', 'bool', 'select']

const styles = {
  body: css`
    overflow: visible !important;
  `,
  noTitle: css`
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  `,
}

class PopupContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: '',
    }
    this.btnOk = React.createRef()
  }

  componentWillReceiveProps({ options, type }) {
    // reset input field when props change (new Popup shown)
    // set to first option if Popup type is "select"
    this.setState({
      data: (type === 'select' && options[0]) || '',
    })
  }

  componentDidUpdate({ type }) {
    if (this.props.type && this.props.type !== type) {
      // setTimeout needed so that the Popup isn't automatically confirmed
      // when open after an Enter event
      setTimeout(() => this.btnOk.current.focus(), 1)
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.shortcuts)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.shortcuts)
  }

  cancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel()
    }
    this.props.cancel()
  }

  submit = (data = this.state.data) => {
    this.props.onSubmit(data)
    this.cancel()
  }

  shortcuts = (e) => {
    // Escape
    if (this.props.type && e.which === 27) {
      this.cancel()
    }
  }

  render() {
    const { type, placeholder, options, title, text } = this.props
    const { data } = this.state

    return (
      <div className='modal-card'>
        {title && (
          <header className='modal-card-head'>
            <h2 className='modal-card-title'>{title}</h2>
          </header>
        )}
        <section
          className={cn(
            'modal-card-body',
            styles.body,
            !title && styles.noTitle,
          )}
        >
          <div className='content'>
            <Markdown source={text} />
          </div>

          {['select', 'prompt'].includes(type) && (
            <div className='field'>
              <div className='control'>
                {type === 'select' && (
                  <Dropdown
                    onChange={(data) => this.setState({ data, hint: '' })}
                    name={data || 'Select'}
                    options={options}
                  />
                )}

                {type === 'prompt' && (
                  <Input
                    autoFocus
                    onChange={(data) => this.setState({ data })}
                    placeholder={placeholder}
                    value={data}
                  />
                )}
              </div>
            </div>
          )}
        </section>

        <footer className='modal-card-foot'>
          {type === 'bool' ? (
            <>
              <button
                autoFocus
                ref={this.btnOk}
                className='button is-success'
                onClick={() => this.submit(true)}
              >
                Yes
              </button>
              <Button name='No' onClick={() => this.submit(false)} />
            </>
          ) : (
            <>
              <button
                autoFocus
                ref={this.btnOk}
                className='button is-success'
                onClick={() => this.submit()}
              >
                Ok
              </button>
              {['prompt', 'confirm', 'select'].includes(type) && (
                <Button name='Cancel' onClick={this.cancel} />
              )}
            </>
          )}
        </footer>
      </div>
    )
  }
}

PopupContent.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  cancel: PropTypes.func,
  type: PropTypes.oneOf(['alert', 'confirm', 'prompt', 'bool', 'select']),
  placeholder: PropTypes.string,
  options: PropTypes.array,
}

PopupContent.defaultProps = {
  text: 'This is a default message.',
}

export default PopupContent
