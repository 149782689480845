import keywords from './keywords'

export default {
  admin: {
    title: 'Admin',
    links: [
      {
        to: '/admin/users',
        icon: 'user-shield',
        label: 'User permissions',
        keywords: keywords.permissions,
        permission: 'admin_users',
      },
      {
        to: '/admin/permissions',
        icon: 'shield-alt',
        label: 'Permission users',
        keywords: keywords.permissions,
        permission: 'admin_users',
      },
      {
        to: '/admin/logs',
        icon: 'list-alt',
        label: 'Logs',
        keywords: keywords.logs,
        permission: 'admin_logs',
      },
    ],
  },
  support: {
    title: 'Support',
    links: [
      {
        to: '/support/user',
        icon: 'users',
        label: 'Users',
        keywords: keywords.users,
        permission: 'support_users',
        useCases: [
          'manage player accounts',
          'search players',
          'inspect and modify data of player accounts',
        ],
      },
      {
        to: '/support/trans',
        icon: 'credit-card',
        label: 'Transactions',
        keywords: keywords.transactions,
        permission: 'support_iaps',
        useCases: ['search transactions by user ID'],
      },
    ],
  },
  mfgcloud: {
    title: 'Cloud',
    links: [
      {
        icon: 'cloud',
        to: '/mfgcloud/attributes',
        label: 'Cloud',
        keywords: keywords.cloud,
        permission: 'mfgcloud',
        useCases: [
          'manage old admin portal users permission',
          'allow IP addresses on Dev Cloud',
          'flush cached CDN files',
          'other kinds of cloud magic (kill instances, inspect settings, set status of hash libraries and batch rewards, ...)',
        ],
      },
      {
        icon: 'sliders-h',
        to: '/mfgcloud/settings',
        label: 'Game Settings',
        keywords: keywords.cloud,
        permission: 'mfgcloud',
        useCases: ['inspect and compare game settings'],
      },
    ],
  },
  games: {
    title: 'Games',
    links: [
      {
        to: '/games/leaderboards',
        icon: 'trophy',
        label: 'Leaderboards',
        permission: 'leaderboards',
        useCases: ["browse players' scores based on chosen Arena ID"],
      },
      {
        to: '/games/events',
        icon: 'calendar',
        label: 'Events',
        permission: 'events',
        useCases: ['manage tournaments for older games'],
      },
      {
        to: '/games/reports',
        label: 'Reports',
        icon: 'flag',
        keywords: keywords.reports,
        permission: 'games_reports',
        useCases: ['browse reports for UK and SGL'],
      },
      {
        to: '/games/SpecialEvent',
        label: 'DT2 special events',
        icon: 'calendar-plus',
        permission: 'games_messages',
        useCases: ['Creating special dt2 event'],
      },
      {
        to: '/games/GameMinVersion',
        label: 'Game min version',
        icon: 'angle-double-up',
        permission: 'minversion',
        useCases: ['Set min version for game'],
      },
      {
        to: '/games/DT2ChatMonitoring',
        label: 'DT2 chat monitoring',
        icon: 'calendar-plus',
        permission: 'leaderboards',
        useCases: ['Monitoring of DT2 chat'],
      },
      {
        to: '/games/messages',
        label: 'Ingame Messages',
        icon: 'comments',
        permission: 'games_messages',
        useCases: [
          'Send in-game messages to all players. They will be able to read the message right in the targeted game. Only Dead Trigger 2 is currently supported.',
        ],
      },
    ],
  },
  games_legends: {
    title: 'Legends',
    links: [
      {
        to: '/games/legends/walk-of-fame',
        label: 'Walk of Fame',
        icon: 'road',
        permission: 'sgl_walk-of-fame',
        useCases: ['add or remove players from SGL Walk of Fame'],
      },
      {
        to: '/games/legends/bad-words',
        label: 'Bad words',
        icon: 'comments',
        permission: 'sgl_bad-words',
        useCases: ['add or remove censored words in SGL'],
      },
      {
        to: '/games/legends/guilds',
        label: 'Guilds',
        icon: 'chess',
        permission: 'sgl_guilds',
        useCases: [
          'search SGL guilds',
          'check SGL guild data',
          'kick SGL guild members',
        ],
      },
      {
        to: '/games/legends/utility',
        label: 'Utility',
        icon: 'mail-bulk',
        permission: 'sgl_generate-offers',
        useCases: ['generate new ingame offers'],
      },
    ],
  },
  marketing: {
    title: 'Marketing',
    links: [
      {
        to: '/marketing/vouchers',
        icon: 'ticket-alt',
        label: 'Vouchers',
        permission: 'vouchers',
        useCases: ['create and edit vouchers for our games'],
      },
      {
        to: '/marketing/contacts',
        icon: 'address-book',
        label: 'Contacts',
        permission: 'marketing_contacts',
        useCases: [
          'browse through contacts data gathered by Marketing service',
          'search contacts',
          'check campaigns of specific contact',
          'generate vouchers for contact',
        ],
      },
    ],
  },
  web: {
    title: 'Web',
    links: [
      {
        to: '/web/cms',
        label: 'CMS',
        icon: 'globe',
        permission: 'web_home',
        useCases: ['add and modify content on our websites'],
      },
      {
        to: '/web/assets',
        label: 'Assets',
        icon: 'images',
        permission: 'web_assets',
        useCases: ['upload and manage existing images used on our websites'],
      },
    ],
  },
  development: {
    title: 'Development',
    links: [
      {
        to: '/development/banning',
        label: 'Banning',
        icon: 'gavel',
        permission: 'development_banning',
        useCases: [
          'this is top secret section',
          "don't ask for permission, you will not get it",
        ],
      },
      {
        to: '/development/appstats',
        label: 'App Stats',
        icon: 'chart-pie',
        permission: 'development_appstats',
        useCases: [
          'this is ALSO top secret section',
          "don't ask for permission, you will not get it",
        ],
      },
    ],
  },
}
