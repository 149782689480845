import { fromJS } from 'immutable'
import _ from 'lodash'

import {
  FETCH_PRODUCT_DATA,
  LOAD_PRODUCT_DATA,
  CHANGE_PRODUCT_DATA,
  SAVE_CHANGES,
} from '../actions'

let INIT = {
  Unkilled: {
    fetching: false,
    originalData: {},
    productData: {},
    characters: [],
    changedFields: [],
    shouldSave: false,
  },
}

export default (state = INIT, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_DATA:
      return fromJS(state)
        .setIn([action.prodId, 'fetching'], true)
        .toJS()

    case LOAD_PRODUCT_DATA:
      return fromJS(state)
        .mergeIn([action.prodId], fromJS(action.data))
        .setIn([action.prodId, 'fetching'], false)
        .toJS()

    case CHANGE_PRODUCT_DATA:
      return fromJS(state)
        .setIn([action.prodId, 'productData', action.field], action.value)
        .setIn([action.prodId, 'shouldSave'], action.shouldSave)
        .updateIn([action.prodId, 'changedFields'], (changedFields) =>
          fromJS(_.union(changedFields.toJS(), [action.field])),
        )
        .toJS()

    case SAVE_CHANGES:
      return fromJS(state)
        .setIn([action.prodId, 'changedFields'], fromJS([]))
        .setIn([action.prodId, 'shouldSave'], false)
        .toJS()

    default:
      return state
  }
}
