import app from 'core/app'

export function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

// TODO: use https://github.com/reduxjs/reselect to avoid re-renders of connected components on each check!
export const withIsFetching = (state) => ({
  // checks if specified request is in progress
  isFetching: (id) => {
    // create array of all in-progress requests with specified request-id
    return app.getRequests(state)
      .filter((req) => req.id)
      .map((req) => req.id)
      .includes(id)
  },
})
