import { fromJS } from 'immutable'

import { EDIT_MESSAGE_FIELD } from './actions'

const initial = {
  DeadTrigger2: {
    subject: '',
    body: '',
    target: '',
    global: false,
  },
}

export default (state = initial, action) => {
  switch (action.type) {
    case EDIT_MESSAGE_FIELD:
      return fromJS(state)
        .setIn([action.product, action.field], action.value)
        .toJS()

    default:
      return state
  }
}
