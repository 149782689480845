import { NAME, LEGENDS_ARENAS } from './constants'

export const FETCH_LEADERBOARD = `${NAME}/FETCH_LEADERBOARD`
export const FETCH_USER_LEADERBOARD = `${NAME}/FETCH_USER_LEADERBOARD`
export const START_FETCH_LDB = `${NAME}/START_FETCH_LDB`
export const EDIT_SCORE = `${NAME}/EDIT_SCORE`
export const SET_PROP = `${NAME}/SET_PROP`
export const DELETE_LDB_USER = `${NAME}/DELETE_LDB_USER`
export const UPDATE_LEADERBOARD = `${NAME}/UPDATE_LEADERBOARD`

export const editScore = (kind, val) => ({
  type: EDIT_SCORE,
  kind,
  val,
})

export const setProp = (property) => ({
  type: SET_PROP,
  property,
})

export const fetchSglArenas = () =>
  setProp({
    available: LEGENDS_ARENAS,
    arenaId: LEGENDS_ARENAS[0].arenaId,
  })
