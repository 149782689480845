import { fromJS } from 'immutable'
import * as types from '../types'

const init = {
  fetching: 0,
}

export const general = (state = init, action) => {
  switch (action.type) {
    case types.LEGENDS_FETCH_START:
      return fromJS(state)
        .set('fetching', state.fetching + 1)
        .toJS()

    case types.LEGENDS_FETCH_STOP:
      return fromJS(state)
        .set('fetching', state.fetching - 1)
        .toJS()

    default:
      return state
  }
}
