import React from 'react'
import styled from 'styled-components'

import Emoji from '@mfg/components/old/Emoji'

class Squirrel extends React.Component {
  state = {
    position: -2,
  }

  intervalID = 0

  componentDidMount() {
    this.intervalID = window.setInterval(this.moveSquirrel, 300)
  }

  componentWillUnmount() {
    clearInterval(this.intervalID)
  }

  moveSquirrel = () => {
    const { position } = this.state
    let newPosition = position + 1
    if (newPosition > 2) {
      newPosition = -2
    }
    this.setState({
      position: newPosition,
    })
  }

  render() {
    return (
      <Styled {...this.state}>
        <span>
          <Emoji emoji='🐿️' width={25} />
        </span>
      </Styled>
    )
  }
}

const Styled = styled('div')`
  font-size: 30px;
  position: relative;
  span {
    position: relative;
    right: ${(props) => props.position * 30}px;
  }
`

export default Squirrel
