const getModel = (state) => state.core.app

export const getSettings = (state) => getModel(state).settings

export const getRequests = (state) => getModel(state).requests

export const isFetching = (state, id) => getRequests(state).map((r) => r.id).includes(id)

export const getHeader = (state) => getModel(state).header

export const getPrevLocation = (state) => getModel(state).prevLocation

export const getInitializing = (state) => getModel(state).initializing

export const getErrors = (state) => getModel(state).errors
