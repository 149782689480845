import storage from '@mfg/common/utils/storage'

const DEFAULT_CLOUD_SETTINGS = {
  cloudVer: 'Production',
  prodId: 'Legends',
  appVer: null,
}

export function getInitialCloudSettings() {
  // try to fetch cloud settings from local storage
  const stored = storage.get('cloud')

  if (!stored || typeof stored !== 'object') {
    return DEFAULT_CLOUD_SETTINGS
  }
  return stored
}
