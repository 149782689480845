import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import styled from 'styled-components'

import Popup from 'components/Popup'
import Link from 'components/Link'
import Dropdown from 'components/Dropdown'
import Modal from 'components/Modal'
import Toast from 'components/Toast'
import ProfilePic from 'components/ProfilePic'
import { getShareQuery } from 'components/GetShareUrl'
import ls from 'local-storage'

import UserSettings from './UserSettings'
import AboutAdmin from './AboutAdmin'

import withCloud from 'utils/withCloud'
import withAuth from 'utils/auth/withAuth'

const BASE_URL = 'https://madfingergames-admin.appspot.com'

class LoggedUser extends Component {
  logout = async () => {
    await Popup.confirm('Are you sure you want to logout?')
    try {
      ls.remove('mfga')
      this.props.logOut()
    } catch (err) {
      console.log({ err })
      Toast.notify('Logging out failed.')
    }
  }

  render() {
    const {
      loggedUser: { defaultUser, username, picture_url },
    } = this.props
    const shareQuery = getShareQuery({
      ...this.props,
      params: ['cloudVer', 'appVer', 'prodId'],
    })

    return (
      <>
        <Dropdown
          className='is-right has-margin-left-small'
          customButton={<StyledProfilePic src={picture_url} />}
        >
          <div className='dropdown-item'>
            {username}@decagames.com
          </div>

          <hr className='dropdown-divider'></hr>
          <a
            className='dropdown-item'
            onClick={() => Modal.show('user-settings')}
          >
            {'Settings'}
          </a>
          <a
            className='dropdown-item'
            onClick={() => Modal.show('about-admin')}
          >
            {'About'}
          </a>

          {defaultUser && (
            <Link
              className='dropdown-item'
              to={`/support/users/search/${defaultUser}`}
            >
              {'Go to Default user'}
            </Link>
          )}

          {process.env.NODE_ENV === 'development' && (
            <Link
              className='dropdown-item'
              to={`${BASE_URL}${window.location.pathname}?${shareQuery}`}
            >
              {'Live version'}
            </Link>
          )}

          <a className='dropdown-item' onClick={this.logout}>
            {'Logout'}
          </a>
        </Dropdown>

        <Modal id='about-admin'>
          <AboutAdmin hide={() => Modal.hide('about-admin')} />
        </Modal>

        <Modal id='user-settings'>
          <UserSettings {...this.props} />
        </Modal>
      </>
    )
  }
}

LoggedUser.propTypes = {
  loggedUser: PropTypes.object.isRequired,
  logOut: PropTypes.func.isRequired,
}

const StyledProfilePic = styled(ProfilePic)`
  cursor: pointer;
  transition: filter .1s ease;
  &:hover {
    filter: drop-shadow(0 0 1px black);
  }
`

export default compose(
  withRouter,
  withCloud(),
  withAuth(),
)(LoggedUser)
