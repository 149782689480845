import * as types from './actions'

let initLoggedUser = {
  username: '',
  cloud: [],
  defaultUser: '',
  permissions: [],
  unauthorized: false,
  loggedOut: false,
}

export default (state = initLoggedUser, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        ...action.user,
        // TODO: move to action
        username: action.user.email.replace('@decagames.com', ''),
      }

    case types.LOGIN_FAIL:
      return { ...state, unauthorized: true }

    case types.LOG_OUT:
      return { ...state, loggedOut: true }

    default:
      return state
  }
}
