import { fromJS } from 'immutable'
import * as types from '../actions'
import produce from 'immer'
import update from 'lodash/update'

const sections = (state = {}, action) => {
  switch (action.type) {
    case types.CLEAR_CMS: {
      return {}
    }
    case types.ADD_CMS_ITEM: {
      const { section, docType, data } = action
      return produce(state, (draft) => {
        update(draft, [section, docType], (list = []) => list.concat(data))
      })
    }

    case types.DELETE_CMS_ITEM: {
      const { section, docType, itemId } = action
      return produce(state, (draft) => {
        const items = draft[section][docType]

        const { _index } = items.find(item => item._id === itemId)

        draft[section][docType] = items
          // remove item
          .filter(item => item._id !== itemId)
          // lower custom _index of higher items
          .map(item => {
            if (item._index > _index) {
              item._index -= 1
            }
            return item
          })
      })
    }

    case types.SWITCH_CMS_ITEMS: {
      const { section, docType, oldIndex, newIndex } = action
      return produce(state, (draft) => {
        const list = draft[section][docType]
        const oldListIndex = list.findIndex(
          item => item._index === oldIndex + 1,
        )
        const newListIndex = list.findIndex(
          item => item._index === newIndex + 1,
        )

        if (oldListIndex < 0 || newListIndex < 0) {
          return
        }

        draft[section][docType][oldListIndex]._index = newIndex + 1
        draft[section][docType][newListIndex]._index = oldIndex + 1
      })
    }

    case types.MOVE_CMS_ITEM_TOP: {
      const { section, docType, itemId } = action
      return produce(state, (draft) => {
        const list = draft[section][docType]
        // find item to be moved
        const topItem = list.find(item => item._id === itemId)

        // move all items above this one
        for (let item of list) {
          if (item._index < topItem._index) {
            item._index += 1
          }
        }

        // move item to the top
        topItem._index = 0
      })
    }

    case types.DELETE_CMS_DOC_TYPE:
    case types.DELETE_CMS_SECTION_DOC_TYPE:
      return fromJS(state)
        .map((sectionData, sectionName) => {
          if (action.sectionName && (action.sectionName !== sectionName)) {
            return sectionData
          }
          return sectionData.delete(action.docTypeName)
        })
        .toJS()

    // CMS doc type metadata have changed so we have to
    // edit all sections with this highly sophisticated method.
    // P.S. We're sorry it has to be this way.
    case types.SET_CMS_DOC_TYPE_FIELDS: {
      const { fields, docType } = action
      return fromJS(state)
        .map((sectionData) => {
          return sectionData.map((docTypeItems, docTypeName) => {
            if (docTypeName !== docType) {
              return docTypeItems
            }

            // we care only about already defined fields
            const docTypeFields = fields
              .filter(field => field.name && field.type)
              .map(field => field.name)

            // get all currently used fields
            const currentDocTypeFields = Object.keys(docTypeItems.get(0))
              .filter(field => field !== '_id')

            // check which fields have been added
            const newFields = docTypeFields
              .filter(field => docTypeItems.get(0).get(field) === undefined)

            // check which fields have been removed
            const removedFields = currentDocTypeFields
              .filter(field => !docTypeFields.includes(field))

            return docTypeItems.map((item) => {
              // add default value to added fields
              for (let field of newFields) {
                item = item.set(field, '')
              }
              // remove any value given to a removed field
              for (let field of removedFields) {
                item = item.delete(field)
              }
              return item
            })
          })
        })
        .toJS()
    }

    case types.ADD_CMS_SECTION:
      return {
        ...state,
        [action.sectionName]: {},
      }

    case types.DELETE_CMS_SECTION:
      return fromJS(state)
        .delete(action.sectionName)
        .toJS()

    case types.ADD_CMS_DOC_TYPE:
      return fromJS(state)
        .setIn(action.path, [])
        .toJS()

    case types.SET_CMS_SECTION:
      return {
        ...state,
        [action.sectionName]: action.data,
      }

    case types.EDIT_CMS_BASIC_ELEMENT: {
      const { section, docType, itemId, propName, value } = action

      return produce(state, (draft) => {
        const item = draft[section][docType].find(i => i._id === itemId)
        item[propName] = value
      })
    }

    default:
      return state
  }
}

export default sections
