import { fromJS } from 'immutable'
import {
  SGL_BAN_INFO,
  ERROR_SGL_USER,
  SET_SGL_USER_PROFILE,
  CLEAR_SGL_USER_INFO,
  EDIT_SGL_USER_PROP,
  ADD_SGL_SKILL,
  REMOVE_SGL_SKILL,
  ADD_SKILL_CATEGORY,
  SET_SGL_TRANSACTIONS,
  SET_SGL_ADS,
  SET_SGL_REPORTS,
  SET_SGL_COMPENSATIONS,
  ADD_SGL_COMPENSATIONS,
  SET_SGL_ALL_FLAGS,
  SET_SGL_USER_FLAGS,
  SET_SGL_BACKUPS,
  SET_SGL_IDENTITIES,
  SET_SGL_USER_GUILD,
  REMOVE_SGL_ITEM,
  SGL_USER_RANKED_UP,
} from './actions'

const init = {
  profile: {},
  profileOriginal: {},
  changedProps: [],
  transactions: [],
  ads: [],
  reports: [],
  reportsOverview: {},
  backups: [],
  ban: {
    history: [],
    current: [],
  },
  compensations: [],
  guild: {},
  flags: [],
  allFlags: [],
  identities: {},
  errors: {},
  didRankUp: false,
}

const ban = (state = init.ban, action) => {
  switch (action.type) {
    case SGL_BAN_INFO:
      return {
        ...state,
        history: action.history,
        current: action.current,
      }

    default:
      return state
  }
}

const errors = (state = init.errors, action) => {
  if (action.type === ERROR_SGL_USER) {
    const err =
      action.err ||
      `There was an error while fetching ${action.section} section.`
    return {
      ...state,
      [action.section]: err,
    }
  }
  return state
}

export default (state = init, action) => {
  let changedProp, propName

  switch (action.type) {
    case SET_SGL_USER_PROFILE:
      return fromJS(state)
        .set('profile', action.profile)
        .set('profileOriginal', action.profile)
        .toJS()

    case CLEAR_SGL_USER_INFO:
      return init

    case EDIT_SGL_USER_PROP:
      // changed prop name must be compatible with cloud endpoint, f.e. "rank", "money" etc.
      propName = (
        action.propName || action.path[action.path.length - 1]
      ).toLowerCase()
      changedProp = {
        path: action.path,
        name: propName,
      }
      if (propName === 'skill') {
        changedProp.path = ['Skills', 'All']
      }
      return fromJS(state)
        .setIn(['profile', ...action.path], action.value)
        .update('changedProps', (changedProps) =>
          changedProps.find((prop) => prop.get('name') === propName)
            ? changedProps
            : changedProps.push(changedProp),
        )
        .toJS()

    case ADD_SGL_SKILL:
      changedProp = {
        path: ['Skills', 'All'],
        name: 'skill',
      }
      return fromJS(state)
        .updateIn(['profile', 'Skills', 'All'], (skills) =>
          (skills || fromJS([])).push(action.skill),
        )
        .update('changedProps', (changedProps) =>
          changedProps.find((prop) => prop.get('name') === 'skill')
            ? changedProps
            : changedProps.push(changedProp),
        )
        .toJS()

    case REMOVE_SGL_SKILL:
      changedProp = {
        path: ['Skills', 'All'],
        name: 'skill',
      }
      return fromJS(state)
        .deleteIn(['profile', 'Skills', 'All', action.index])
        .update('changedProps', (changedProps) =>
          changedProps.find((prop) => prop.get('name') === 'skill')
            ? changedProps
            : changedProps.push(changedProp),
        )
        .toJS()

    case ADD_SKILL_CATEGORY:
      return fromJS(state)
        .updateIn(['profile', 'Skills', 'All'], (skills) => {
          if (!skills) {
            return [action.category]
          }
          return skills.push(action.category)
        })
        .toJS()

    case SET_SGL_TRANSACTIONS:
      return fromJS(state)
        .set('transactions', action.transactions)
        .toJS()

    case SET_SGL_ADS:
      return fromJS(state)
        .set('ads', action.ads)
        .toJS()

    case SET_SGL_REPORTS:
      return fromJS(state)
        .set('reports', action.reports)
        .set('reportsOverview', action.reportsOverview)
        .toJS()

    case SET_SGL_COMPENSATIONS:
      return {
        ...state,
        compensations: action.compensations,
      }

    case ADD_SGL_COMPENSATIONS:
      return {
        ...state,
        compensations: [...action.compensations, ...state.compensations],
      }

    case SET_SGL_ALL_FLAGS:
      return {
        ...state,
        allFlags: action.flags,
      }

    case SET_SGL_USER_FLAGS:
      return {
        ...state,
        flags: action.flags,
      }

    case SET_SGL_BACKUPS:
      return {
        ...state,
        backups: action.backups,
      }

    case SET_SGL_IDENTITIES:
      return {
        ...state,
        identities: action.identities,
      }

    case SET_SGL_USER_GUILD:
      return {
        ...state,
        guild: action.guild,
      }

    case REMOVE_SGL_ITEM:
      return fromJS(state)
        .updateIn(['profile', 'Storage', 'Groups', 'Items'], (items) =>
          items.filter((item) => item.get('ID') !== action.itemId))
        .toJS()

    case SGL_USER_RANKED_UP:
      return { ...state, didRankUp: true }

    default:
      return {
        ...state,
        ban: ban(state.ban, action),
        errors: errors(state.errors, action),
      }
  }
}
