import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Icon from './Icon'

class Input extends React.Component {
  state = {
    isInRange: true,
  }

  onChange = (val) => {
    const { type } = this.props
    this.checkValidity(val)
    if (type === 'number') {
      let value = parseInt(val, 10)
      if (Number.isNaN(value)) {
        this.props.onChange(val)
      } else {
        this.props.onChange(value)
      }
      return
    }
    this.props.onChange(val)
  }

  onKeyDown = (e) => {
    if (this.props.onKeyDown) {
      this.props.onKeyDown(e)
    }
    if (e.key === 'Enter' && this.props.onEnter) {
      this.props.onEnter(this.props.value)
    }
  }

  onBlur = (e) => {
    const { range, type, value } = this.props
    if (this.props.onBlur) {
      this.props.onBlur(e)
    }
    // only apply for number inputs with range
    if (!range || (range && type !== 'number')) {
      return
    }
    let num = parseInt(value, 10)
    const [min, max] = range
    if (Number.isNaN(num) || num < min) {
      this.onChange(min)
    }
    if (max !== undefined && num > max) {
      this.onChange(max)
    }
  }

  checkValidity = (value) => {
    const { range, type, required } = this.props
    const { isInRange } = this.state
    if (value === '' && required) {
      this.setState({ isInRange: false })
      return false
    }
    if (!range) {
      return true
    }
    const [min, max = Infinity] = range
    let num = parseInt(value, 10)
    // check text string length if type is not number
    if (type === 'text') {
      num = (value || '').length
    }
    // raise error and don't allow user to leave feild
    // until required range is satisfied
    if ((type === 'number' && Number.isNaN(num)) || num < min || num > max) {
      if (isInRange) {
        this.setState({ isInRange: false })
      }
      return false
    }
    if (!isInRange) {
      this.setState({ isInRange: true })
    }
    if (!value && required) {
      this.setState({ isInRange: false })
      return false
    }
    return true
  }

  getButtonsArray = () => {
    const { buttons } = this.props
    if (!buttons) {
      return []
    }
    if (!Array.isArray(buttons)) {
      return [buttons]
    }
    return buttons
  }

  render() {
    const {
      className,
      controlClassName,
      label,
      range,
      help,
      onEnter, // eslint-disable-line
      icon,
      flex,
      buttons,
      tooltip,
      showTooltip,
      ...rest
    } = this.props
    const { isInRange } = this.state
    const isSimple = !label && !help && !icon && !range && !buttons

    // create default input component
    const input = (
      <div
        className={cn('control', controlClassName, icon && 'has-icons-left', {
          'is-tooltip-active': showTooltip,
          tooltip: !!tooltip,
        })}
        data-tooltip={tooltip}
        style={flex && styles.flex}
      >
        <input
          {...rest}
          data-testid='input'
          className={cn('input', isSimple && className, {
            'is-danger': !isInRange,
          })}
          onChange={(e) => this.onChange(e.target.value)}
          onKeyDown={this.onKeyDown.bind(this)}
          onBlur={this.onBlur}
          onEnter={this.onEnter}
        />
        {icon && <Icon className='is-small is-left' icon={icon} />}
      </div>
    )
    // normally you only want to render the input component
    if (isSimple) {
      return input
    }
    // if specifying label or range, we are creating a "rich" Bulma input
    // with label above and possible hint text below
    return (
      <div className={cn('field', className, buttons && 'has-addons')}>
        {label && (
          <label className='label' style={styles.label}>
            {label}
          </label>
        )}

        {input}

        {this.getButtonsArray().map((button, i) => (
          <div key={i} className='control'>
            {button}
          </div>
        ))}

        {help && <div className='help'>{help}</div>}

        {!isInRange && (
          <div className='help' data-testid='range-error'>
            {`Value not in range from ${range[0]} to ${
              range[1] || 'infinity'
            }.`}
          </div>
        )}
      </div>
    )
  }
}

const styles = {
  label: {
    whiteSpace: 'nowrap',
  },
  flex: {
    flex: 1,
  },
}

Input.defaultProps = {
  type: 'text',
  value: '',
}

Input.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  flex: PropTypes.bool,
  required: PropTypes.bool,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  controlClassName: PropTypes.string,
  disabled: PropTypes.bool,
  range: PropTypes.array,
  icon: PropTypes.string,
  tooltip: PropTypes.string,
  showTooltip: PropTypes.bool,
  buttons: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onEnter: PropTypes.func,
  onBlur: PropTypes.func,
  type: PropTypes.string,
  help: PropTypes.string,
}

export default Input
