import { NAME } from './constants'

export const START_SEARCH = `${NAME}/START_SEARCH`
export const SEARCH_DONE = `${NAME}/SEARCH_DONE`
export const CLEAR_SEARCH = `${NAME}/CLEAR_SEARCH`
export const EDIT_SEARCH = `${NAME}/EDIT_SEARCH`
export const UPDATE_USERS = `${NAME}/UPDATE_USERS`
export const ADD_USERS = `${NAME}/ADD_USERS`
export const SET_SORT = `${NAME}/SET_SORT`
export const TOGGLE_FETCH_ALL = `${NAME}/TOGGLE_FETCH_ALL`
export const TOGGLE_FIELD = `${NAME}/TOGGLE_FIELD`
export const TOGGLE_PRODUCT = `${NAME}/TOGGLE_PRODUCT`
export const EDIT_RANGE = `${NAME}/EDIT_RANGE`
export const CLEAR_RANGE = `${NAME}/CLEAR_RANGE`
export const SET_TOOLBAR = `${NAME}/SET_TOOLBAR`
