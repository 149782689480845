import * as types from './actions'

const VIEW_TYPES = ['List', 'Box']

const init = {
  assets: [],
  filter: '',
  viewType: 'List',
  fileType: 'All',
}

export default (state = init, action) => {
  switch (action.type) {
    case types.ADD_ASSETS:
      return {
        ...state,
        assets: state.assets.concat(action.assets),
      }

    case types.REMOVE_ASSET:
      return {
        ...state,
        assets: state.assets.filter((asset) => asset.path !== action.path),
      }

    case types.EDIT_FILE_TYPE:
      return { ...state, fileType: action.fileType }

    case types.EDIT_VIEW_TYPE: {
      const viewType = VIEW_TYPES[VIEW_TYPES.indexOf(state.viewType) ? 0 : 1]
      return { ...state, viewType }
    }

    case types.EDIT_ASSET_FILTER:
      return { ...state, filter: action.filter }

    default:
      return state
  }
}
