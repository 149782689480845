import { NAME } from './constants'

export const LOAD_USERS = `${NAME}/LOAD_USERS`
export const ADD_USER = `${NAME}/ADD_USER`
export const ENABLE_USER = `${NAME}/ENABLE_USER`
export const EDIT_USER = `${NAME}/EDIT_USER`
export const DELETE_USER = `${NAME}/DELETE_USER`
export const ADD_PERMISSION = `${NAME}/ADD_PERMISSION`
export const REMOVE_PERMISSION = `${NAME}/REMOVE_PERMISSION`
export const DELETE_PERMISSION = `${NAME}/DELETE_PERMISSION`

export const fetchUsers = (users) => ({
  type: LOAD_USERS,
  users,
})

export const createUser = (user) => ({
  type: ADD_USER,
  user,
})

export const enableUser = (user) => ({
  type: ENABLE_USER,
  user,
})

export const editUser = (index, key, value) => ({
  type: EDIT_USER,
  index,
  key,
  value,
})

export const deleteUser = (index) => ({
  type: DELETE_USER,
  index,
})

export const addPermission = (username, permission) => ({
  type: ADD_PERMISSION,
  username,
  permission,
})

export const removePermission = (username, permission) => ({
  type: REMOVE_PERMISSION,
  username,
  permission,
})


