import moment from 'moment'

import { NAME } from './constants'

export const LOAD_NOTES = `${NAME}LOAD_NOTES`
export const SAVE_NOTES = `${NAME}SAVE_NOTES`
export const START_EDIT_NOTE = `${NAME}START_EDIT_NOTE`
export const UPDATE_NOTE = `${NAME}UPDATE_NOTE`
export const ADD_NOTE = `${NAME}ADD_NOTE`
export const DELETE_NOTE = `${NAME}DELETE_NOTE`
export const CLEAR_NOTES = `${NAME}CLEAR_NOTES`

export const clearUserNotes = () => ({
  type: CLEAR_NOTES,
})

export const addUserNote = (author) => ({
  type: ADD_NOTE,
  note: {
    date: moment().format(),
    text: '',
    author,
  },
})

export const deleteUserNote = (index) => ({
  type: DELETE_NOTE,
  index,
})

export const startEditUserNote = (index) => ({
  type: START_EDIT_NOTE,
  index,
})

export const updateUserNote = (index, text) => ({
  type: UPDATE_NOTE,
  index,
  text,
})
