const getModel = (state) => state.core.cloud

export const getCloud = getModel

export const getAllPermissions = (state) => getModel(state).allPermissions

export const getAppVer = (state) => getModel(state).appVer

export const getCloudVer = (state) => getModel(state).cloudVer

export const getProdId = (state) => getModel(state).prodId
